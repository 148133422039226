<template>
	<div>
		<page
			v-if="prov && prov.setup"
			class="pt-6"
			:id="$route.params.id"
			:remote="remoteFunc"
			:remote-option="dynamicData"
			@on-change="onChange"
			@on-input-change="onInputChange"
			scriptforge-endpoint="/api/softphone/scriptforge"
			page-endpoint="/api/softphone/page"
		></page>
	</div>
</template>
<style>
.form-ag-grid.ag-grid .ag-theme-balham {
	height: 20rem !important;
}
.el-button--mini, .el-button--mini.is-round {
	padding-top: 3px !important;
	padding-bottom: 3px !important;
}
.text-success {
    color: #85ce61;
}
.text-info {
    color: #9fa4ad;
}
.ag-theme-balham .bold, .bold {
    font-weight: 500;
}
.ag-theme-balham .bold, .bold span {
    font-weight: 800;
}
</style>
<script>
import Page from '@/common/src/components/Page/Compact-Page.vue'
import { mapState } from 'vuex'
export default {
	components: {Page},
	data () {
		return {
			dynamicData: {}
		}
	},
	computed: {
		...mapState({
			prov: store => store.prov
		}),
		remoteFunc () {
			return {
				makeCall: this.makeCall,
				endCall: this.endCall,
				toggleMic: this.toggleMic,
				toggleHold: this.toggleHold,
				getProv: this.getProv
			}
		}
	},
	methods: {
		onChange (field, value, data) {
			console.log('--on-change--', field, value, data)
		},
		onInputChange (value) {
			console.log('--on-input-change--', value)
		}
	}
}
</script>
