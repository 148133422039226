<script>
import CompactElementUi from './Compact-Element-Ui.vue'
import GenerateForm from '../GenerateForm.vue'
import GenerateColItem from './GenerateColItem'
import GenerateTabItem from './GenerateTabItem'
import GenerateReport from './GenerateReport'
import GenerateInline from './GenerateInline'
import GenerateCollapse from './GenerateCollapse.vue'
import GenerateDialog from './GenerateDialog.vue'

export default {
	extends: GenerateForm,
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateColItem,
		GenerateTabItem,
		GenerateReport,
		GenerateInline,
		GenerateCollapse,
		GenerateDialog
	},
	mixins: [CompactElementUi]
}
</script>
