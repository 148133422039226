<template>
	<div class="box" v-show="html">
		<div class="box" v-show="!hasContainer" v-html="html"></div>
		<v-container v-show="hasContainer" grid-list-xs>
			<v-layout>
				<v-flex xs12 sm10 md8 lg6>
					<p v-html="html"></p>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<style scoped>
.box, iframe {
    flex: 1 1 auto;
    display: unset; /* new */
}
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
	data () {
		return {
			html: '',
			hasContainer: false
		}
	},
	created () {
		this.loadPage()
	},
	methods: {
		...mapActions([
			'getProv'
		]),
		async loadPage () {
			await this.getProv()
			if (!this.content) {
				this.html = false
			} else {
				const page = this.content.find(x => x.id === this.$route.params.id)
				this.html = page ? page.html : ''
			}
			this.hasContainer = this.html && !this.html.includes('<!-- NO-CONTAINER -->')
		}
	},
	computed: {
		...mapState({
			content: state => state.prov.menu
		})
	}
}
</script>
