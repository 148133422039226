<template>
	<div v-if="showForm" :class="hidden && 'zero'">
		<generate-form
			ref="generateForm"
			:data="data"
			:remote="remoteFunc"
			:remote-option="dynamicData"
			@on-change="onChange"
			@on-input-change="onInputChange"
			:scriptforge-endpoint="scriptforgeEndpoint"
		></generate-form>
	</div>
</template>
<style scoped>
.zero {
	width: 0;
	height: 0;
	display: none;
}
</style>
<script>
import GenerateForm from '@/common/src/form-making/components/GenerateForm.vue'
import axios from 'axios'

export default {
	components: { GenerateForm },
	props: {
		pageUrl: String,
		hidden: Boolean,
		id: [Number, String],
		scriptforgeEndpoint: String,
		pageEndpoint: String
	},
	data () {
		return {
			pending: false,
			data: {},
			showForm: false,
			remoteFunc: {},
			dynamicData: {}
			// dynamicData: {
				// optionProvince : [], // уюЂ option data // https://www.yuque.com/form-making/es9x0r/atcv5h
			// }
		}
	},
	created () {
		if (!this.hidden) this.renderForm(false, this.$route.query)
	},
	methods: {
		async renderForm (id = false, formData = {}) {
			try {
				this.showForm = false
				this.pending = true
				const res = await axios.get(`${this.pageEndpoint}/${id || this.id}`)
				this.data = res.data.data
				// If we're showing a form which is not a dialog and we're not on that page
				if (!this.id && !this.isPageDialog(this.data)) {
					if (formData?.ids?.[0]?.id) formData.ids = formData.ids.map(row => row.id)
					this.$router.push({name: this.pageUrl, query: formData, params: {id}})
					return
				}
				this.$nextTick(() => {
					if (Object.keys(this.data).length) {
						this.showForm = true
					}
					this.$nextTick(() => {
						if (this.isPageDialog(this.data)) {
							const dialog = this.$refs.generateForm.getComponent(this.data.list[0].model)
							dialog.setData(formData)
						} else {
							this.$refs.generateForm.setData(formData)
						}
					})
				})
			} catch (err) {
				if (this.axiosErr) this.axiosErr(err)
				console.error(err)
			} finally {
				this.pending = false
			}
		},
		isPageDialog (data) {
			if (!data?.list.length) return false
			return data?.list[0].type === 'dialog' && data?.list[0].options?.visible
		},
		onChange (field, value, data) {
			console.log('--on-change--', field, value, data)
		},
		onInputChange (value) {
			console.log('--on-input-change--', value)
		}
	}
}
</script>
