<template>
	<v-container class="cdr">
		<span class="d-flex"><h1>CDR</h1><h3> - &nbsp;last 30 days - <i style="font-weight: 300; color: #97cd94;">{{recordingPlaiying && !waveSurfer?.isReady ? 'loading' : ''}}</i></h3></span>
		<i v-show="!recordingPlaiying" style="display: flex; margin: 2rem .15rem; opacity: .35; text-align: center;">Select a recording to play</i>
		<div v-show="recordingPlaiying" id="waveform" style="margin: 2rem 0 3rem;"></div>
		<v-data-table :headers="parsedCdrList.headers" :items="parsedCdrList.cdrConfig" :items-per-page="8" class="elevation-1 mt-6">
			<template v-slot:top>
				<v-btn v-if="!waveSurfer?.isPlaying()" plain x-large  style="padding-left: 0.35rem;">
					<v-icon color="green" x-large @click="waveSurfer?.play()">mdi-play-outline</v-icon>
					&nbsp;&nbsp;{{ recordingPlaiying }}
				</v-btn>
				<v-btn v-else plain x-large style="padding-left: 0.35rem;">
					<v-icon  color="red" @click="waveSurfer?.pause()" x-large>mdi-pause-circle-outline</v-icon>
					&nbsp;&nbsp;{{ recordingPlaiying }}
				</v-btn>
			</template>
			<!-- eslint-disable-next-line -->
			<template v-slot:item.recordingCallid="{item}">
				<span  v-if="item.recording_available" style="display: flex;">
					<v-btn icon medium>
						<v-icon @click="() => {playSelected(item)}">mdi-play-outline</v-icon>
					</v-btn>
					<v-btn icon medium class="ml-3">
						<v-icon @click="() => {downloadSelected(item)}">mdi-download-outline</v-icon>
					</v-btn>
				</span>
				<span v-else class="ml-3">None</span>
			</template>
		</v-data-table>
</v-container>
</template>
<style scoped>
span {
	align-items: center;
}
h3 {
	color: #c9c9c9;
	margin-left: 1rem;
}
</style>

<script>
import WaveSurfer from 'wavesurfer.js'
import {mapActions, mapState} from 'vuex'

export default {
	data () {
		return {
			waveSurfer: null,
			dialogDelete: false,
			parsedCdrList: { headers: [], cdrConfig: []},
			recordingPlaiying: '',
			// indexToDelete: -1,
			dataLoaded: false
		}
	},
	computed: {
		...mapState({
			cdr: state => state.cdr,
			uuid: state => state.uuid
		})
	},
	async mounted () {
		if (!document.querySelector('#waveform')) return

		await this.getCdr()
		this.parsedCdrList = await this.parseCdrList()

		if (this.cdr.headers?.length && this.cdr.cdrConfig?.length) this.dataLoaded = true
		this.waveSurfer = WaveSurfer.create({
			container: '#waveform',
			waveColor: 'violet',
			progressColor: 'purple'
		})
		window.waveSurfer = this.waveSurfer
		// this.waveSurfer.load(this.parsedCdrList.cdrConfig[0].recordingUrl)
		// this.recordingPlaiying = this.parsedCdrList.cdrConfig[0].callid
	},
	methods: {
		...mapActions(['getCdr']),
		async parseCdrList () {
			if (!this.cdr || this.cdr.constructor !== Array) return { headers: [], cdrConfig: [] }
			const callIdList = []
			let cdrConfig = this.cdr.map((call) => {
				const created = this.$moment(new Date(call.dt), 'MMM Do YYYY - h:mm A') // Giving a default formatting is just to prevent errors in mozilla
				if (window) window.tx = this.$moment

				callIdList.push(call.callid) // Create aList of call IDs to get related jobs in script forge as a bulk process

				return {
					...call,
					duration: this.duration(call.duration),
					dt: created.format('MMM Do YYYY - h:mm A'),
					dest_cli: call.dest_cli[0]?.toUpperCase() + call.dest_cli.substr(1),
					recordingUrl: `${window.location.protocol}//${window.location.host}/api/softphone/cdr/recording/${call.dt.split(' ')[0]}/${call.callid}.wav`
				}
			})
			const headers = [
				{ text: 'Call ID', value: 'callid' },
				{ text: 'Destination CLI', value: 'dest_cli' },
				{ text: 'Destination Number', value: 'dest_number' },
				{ text: 'Call Time', value: 'dt' },
				{ text: 'Duration', value: 'duration' },
				{ text: 'Recording', value: 'recordingCallid' }
			]

			// Customer fields for Customer Wise
			if (window.location.href.includes('voip.customerwise') || window.location.href.includes('webphone.cacious.connexcs.com')) {
				const scriptForgeId = window.location.href.includes('voip.customerwise') ? '2329' : '1300'

				const callJobRelation = await this.runScriptForge(scriptForgeId, 'getCallJobRelation', { callIdList})
				// If there are a number of call job relations, otherwise, just output the call data without the related job data
				if (callJobRelation?.length) {
					headers.unshift({ text: 'Client Name', value: 'client_name'})
					headers.unshift({ text: 'direction', value: 'call_direction'})
					headers.unshift({ text: 'Job ID', value: 'job_id'})
					cdrConfig = cdrConfig.map(call => {
						const callJob = callJobRelation.filter(rel => rel.call_id === call.callid)[0]
						return {
							...call,
							callid: `${call.callid.substr(0, 10)}...`,
							recordingCallid: call.callid,
							job_id: callJob?.job_id ? callJob.job_id : '-',
							client_name: callJob?.client_name ? callJob.client_name : '-',
							call_direction: callJob?.call_direction ? callJob.call_direction : '-'
						}
					})
				}
			}

			return { headers, cdrConfig}
		},
		duration (value) {
			if (value === undefined || value === null) return '-'
			const parsedValue = parseFloat(value)
			if (!parsedValue) return '0 min'
			if (parsedValue > 0 && parsedValue < 1) return (Math.round(parsedValue * 1000)) + ' ms'
			if (parsedValue < 60) return parsedValue.toFixed(2) + ' sec'
			const min = Math.floor(parsedValue / 60)
			let sec = (parsedValue - (min * 60)) % 60
			sec = sec?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 4})
			return min.toLocaleString() + ' min ' + sec + ' sec'
		},
		async playSelected (cdr) {
			if (!cdr) return
			this.recordingPlaiying = cdr.callid
			if (this.waveSurfer.isPlaying) this.waveSurfer.pause()
			await this.waveSurfer.load(cdr.recordingUrl)
			this.waveSurfer.on('ready', () => {
				this.waveSurfer.play()
				this.waveSurfer.unAll()
			})
		},
		downloadSelected (cdr) {
			return Object.assign(document.createElement('a'), { href: cdr.recordingUrl, target: '_blank' }).click()
		}
	}

}
</script>
