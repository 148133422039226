<script>
import CompactElementUi from './Compact-Element-Ui.vue'
import GenerateCollapse from '../GenerateCollapse.vue'
import GenerateInline from './GenerateInline.vue'
import GenerateColItem from './GenerateColItem.vue'
import GenerateReport from './GenerateReport.vue'
import GenerateTabItem from './GenerateTabItem.vue'

export default {
	extends: GenerateCollapse.vue,
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateInline,
		GenerateColItem,
		GenerateReport,
		GenerateTabItem
	},
	mixins: [CompactElementUi]
}
</script>
