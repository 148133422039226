<template>
	<v-container grid-list-xs text-xs-center>
		<v-layout justify-center>
			<v-flex :class="popup ? '' : 'xs12 sm10 md8 lg6'">
				<v-card style="background-color: transparent;box-shadow: unset;">
					<v-form ref="form" @submit.prevent.native>
						<v-text-field label="Display Name" v-model="formData.displayName" :rules="[rules.required]"></v-text-field>
						<v-text-field label="CLI" v-model="formData.cli"></v-text-field>
						<v-text-field label="Sip User" v-model="formData.username" :rules="[rules.required]"></v-text-field>
						<v-text-field label="Sip Pass" v-model="formData.password" :rules="[rules.required]" :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"></v-text-field>
						<v-text-field label="Sip Realm" v-model="formData.realm" :rules="[rules.required]"></v-text-field>
						<v-text-field v-if="popup" label="WS URL" v-model="wsServer" :disabled="popup"></v-text-field>
						<v-text-field v-else label="WS URL" v-model="formData.wsServer" :rules="[rules.required]"></v-text-field>
					</v-form>
					<div v-if="prov && prov.flags && prov.flags.includes('EDIT_SETTINGS')">
						<v-btn block color="success" ripple @click="submit" id="btn_settings_submit">{{popup ? 'Continue' : 'Save'}}</v-btn>
						<v-btn block text @click="clear" class="mt-2" id="btn_settings_clear">clear</v-btn>
					</div>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
export default {
	props: {
		popup: Boolean
	},
	data () {
		return {
			showPass: false,
			formData: { displayName: '', cli: '', username: '', password: '', realm: '', wsServer: '' },
			rules: {
				required: v => !!v || 'Required',
				fqdn: v => /^(?=^.{1,253}$)(([a-z\\d]([a-z\\d-]{0,62}[a-z\\d])*[\\.]){1,3}[a-z]{1,61})$/.test(v) || 'Enter Valid URL',
				wss: v => /^wss:\/\/(?=^.{1,253}$)(([a-z\\d]([a-z\\d-]{0,62}[a-z\\d])*[\\.]){1,3}[a-z]{1,61})$/.test(v) || 'Format eg: wss://URL'
			}
		}
	},
	mounted () {
		// {
			// username: 'ramu2', // User Name
			// password: '7Wh8MXdiBU', // Password
			// realm: 'sip-lon-3.host.connexcs.net', // Auth Realm
			// displayName: 'Ramu N', // Display Name
			// wsServer: 'wss://sip-lon-3.host.connexcs.net' // WebSocket URL
		// }
		if (localStorage.getItem('SIPConfig') === null) return
		this.formData = JSON.parse(localStorage.getItem('SIPConfig'))
		this.$bus.$on('provision', () => {
			this.formData = JSON.parse(localStorage.getItem('SIPConfig'))
		})
	},
	computed: {
		...mapState({
			prov: state => state.prov,
			uuid: state => state.uuid
		}),
		isStandalone () {
			return this.prov && !this.prov.setup && this.prov.flags && this.prov.flags.includes('STANDALONE')
		},
		wsServer () {
			return 'wss://' + this.formData.realm
		}
	},
	created () {
		this.getProv()
	},
	methods: {
		...mapActions([
			'getProv'
		]),
		async submit () {
			if (!this.$refs.form.validate()) {
				this.throwErr('Either required fields are not filled in or validation is failed.')
				return
			}
			try {
				this.throwErr('Saved Successfully')
				if (this.isStandalone) this.formData.wsServer = this.wsServer
				localStorage.setItem('SIPConfig', JSON.stringify(this.formData))
				if (this.isStandalone) {
					this.$store.commit('setProv', { ...this.prov, standalone: true })
					this.$root.$children[0].register()
				} else {
					await axios.post(`/api/softphone/settings?uuid=${this.uuid}`, this.formData)
					this.getProv()
				}
			} catch (err) {
				this.throwErr(err)
			}
		},
		clear () {
			this.$refs.form.reset()
			localStorage.removeItem('SIPConfig')
		}
	}
}
</script>
