<template>
	<div v-if="dataType">
		<pre v-if="dataType === 'object'">{{newContent}}</pre>
		<p v-else-if="dataType === 'text'">{{newContent}}</p>
		<div v-else-if="dataType === 'html'" v-html="newContent"></div>
	</div>
</template>

<script>
export default {
	props: ['dataType', 'content'],
	computed: {
		newContent () {
			return this.content
		}
	}
}
</script>
