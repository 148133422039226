<template>
	<div v-if="prov.setup && buttonList.length">
		<v-list-item v-for="(btn, idx) in buttonList" :key="idx" @click="dynamicButtonClick(btn)" link>
			<dynamic-button :ref="`dynamicButton-${btn.id}`" :data="btn" placement="side_nav" :color="buttonTypeColors[btn.type]"/>
		</v-list-item>
	</div>
</template>
<style>
.v-input--selection-controls .v-radio>.v-label {
	top: .5rem;
}
</style>
<script>
import DynamicButton from './Dynamic-Button.vue'
import { mapState, mapActions } from 'vuex'

export default {
	components: { DynamicButton },
	watch: {
		prov (newProv) {
			if (newProv.setup) {
				/* Load Buttons */
				try {
					this.getButton()
				} catch (err) {
					this.throwErr(err)
				}
			}
		}
	},
	computed: {
		...mapState({
			prov: state => state.prov,
			uuid: state => state.uuid,
			button: state => state.button
		}),
		buttonList () {
			if (!this.button.length) return []
			return this.button
				.filter(btn => btn.area === 'webphone/side_nav')
				.map(btn => { return { ...btn, placement: btn.area.split('/')[1] } })
				.sort((a, b) => a.sort_order - b.sort_order)
		}
	},
	methods: {
		...mapActions(['getButton']),
		dynamicButtonClick (btn) {
			/* If btn is a form object, then open it right away */
			const btnComponent = this.$refs[`dynamicButton-${btn.id}`][0]
			if (btn.schema) {
				btnComponent.openLoadedForm(btn)
			} else {
				btnComponent.dynamicButtonClick(btn)
			}
		}
	}
}
</script>
