<template>
	<v-data-table id="call-stats-table" :loading="loading" loading-text="Downloading... Please wait..." :items-per-page="itemsPerPage" :headers="headers" :calculate-widths="true" :items="data"></v-data-table>
</template>
<script>
export default {
	props: {
		data: {
			type: Array,
			default: () => [],
			description: 'The call statistics data to display.'
		},
		itemsPerPage: {
			type: Number
		},
		loading: {
			type: Boolean
		}
	},
	data () {
		return {
			parsedSearch: '',
			headers: [
				{ text: 'Time', align: 'start', sortable: false, width: '7rem', value: 'time' },
				{ text: 'Timestamp', sortable: true, width: '7rem', value: 'timestamp' },
				{ text: 'Codec', value: 'codec' },
				{ text: 'Jitter', value: 'jitter' },
				{ text: 'Packets Received', value: 'packetsReceived' },
				{ text: 'Packets Sent', value: 'packetsSent' },
				{ text: 'Packets Lost', value: 'packetsLost' },
				{ text: 'Bytes Received', value: 'bytesReceived' },
				{ text: 'Bytes Sent', value: 'bytesSent' },
				{ text: 'Round Trip Time', value: 'roundTripTime' }
			]
		}
	},
	computed: {
		search: {
			get: function () { return this.parsedSearch },
			set: function (newSearchTerm) { this.parsedSearch = newSearchTerm.split(':').map(val => val.trim()).join(' : ') }
		}
	}
}
</script>
