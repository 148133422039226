<template>
	<v-dialog fullscreen v-if="showDialog" max-width="100rem" width="85rem" transition="dialog-bottom-transition">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on" link>
				<v-list-item-action>
					<v-icon color="grey">mdi-phone-log</v-icon>
				</v-list-item-action>
				<v-list-item-subtitle>Call Stats</v-list-item-subtitle>
			</v-list-item>
		</template>
		<template v-slot:default="dialog">
			<v-card>
				<v-toolbar color="primary" dark>Call Statistics</v-toolbar>
				<v-card-text>
					<call-stats-logs :itemsPerPage='itemsPerPage' @pageChange="onTablePageChange" :loading="downloading" style="box-shadow: none;margin-top: .5rem;" :data="sipClient.callStats"/>
				</v-card-text>
				<v-card-actions class="justify-end absolute-top-right">
					<v-btn color="white" text @click="dialog.value = false">Close</v-btn>
					<v-btn color="white" text @click="downloadCallStats">Download As CSV</v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>
</template>
<style>
	.absolute-top-right {
		position: absolute;
		top: .5rem;
		right: 0;
	}
</style>
<script>
import { mapState, mapActions } from 'vuex'
import CallStatsLogs from './Call-Stats-Logs.vue'
export default {
	components: { CallStatsLogs },
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			itemsPerPage: 15,
			downloading: false
		}
	},
	computed: {
		...mapState({
			prov: state => state.prov
		}),
		showDialog () {
			return this.sipClient?.session?.state === 'Established'
		},
		callStatsTitle () {
			return `call-stats-logging-${this.prov?.sipUser?.username}-${this.sipClient?.dialedNumber}.csv`
		}
	},
	methods: {
		...mapActions([
			'getProv'
		]),
		onTablePageChange (val) {
			this.itemsPerPage = val
		},
		downloadCallStats () {
			const changePage = new Promise((resolve, reject) => {
				const tempItemsPerPage = this.itemsPerPage
				this.itemsPerPage = -1
				if (this.downloading) {
					reject(new Error('Downloading... Please wait...'))
				} else {
					this.downloading = true
					resolve(tempItemsPerPage)
				}
			})

			changePage.then(prevPage => {
				this.downloadTableAsCsv('#call-stats-table table', this.callStatsTitle, prevPage)
			}).catch(error => {
				this.throwErr(error.message)
			})
		},
		downloadTableAsCsv (tableSelector, filename, prevItemsPerPage) {
			// setTimeout(() => {
			const data = []
			const rows = document.querySelectorAll(`${tableSelector} tr`)
			for (let i = 0; i < rows.length; i++) {
				const row = []
				const cols = rows[i].querySelectorAll('td, th')

				for (let j = 0; j < cols.length; j++) {
					row.push(cols[j].innerText)
				}
				data.push(row.join(','))
			}

			const csv_file = new Blob([data.join('\n')], { type: 'text/csv' })
			const download_link = document.createElement('a')

			download_link.download = filename
			download_link.href = window.URL.createObjectURL(csv_file)
			download_link.style.display = 'none'
			document.body.appendChild(download_link)
			download_link.click()
			this.downloading = false
			this.itemsPerPage = prevItemsPerPage
		}
	},
	async mounted () {
		await this.getProv()
	}
}
</script>
