<script>
import CompactElementUi from './Compact-Element-Ui.vue'
import GenerateColItem from '../GenerateColItem.vue'
import GenerateTabItem from './GenerateTabItem'
import GenerateInline from './GenerateInline'
import GenerateReport from './GenerateReport'
import GenerateCollapse from './GenerateCollapse'

export default {
	extends: GenerateColItem,
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateTabItem,
		GenerateInline,
		GenerateReport,
		GenerateCollapse
	},
	mixins: [CompactElementUi]
}
</script>
