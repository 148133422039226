import axios from 'axios'
import FormValidationRules from '../config/form-validation-rules.js'
let currentUserFormPromise = null

export default {
	data () {
		return {
			copying: false,
			debounceMap: {}
		}
	},
	computed: {
		isCp () {
			return this._api.includes('cp')
		},
		isPortal () {
			return this._api.includes('portal')
		},
		account () {
			return this.isCp ? this.$store.state.setup.account : false
		},
		self () {
			return this.isCp ? this.$store.state.setup.self : false
		},
		isDeletePermitted () {
			if (this.isCp) {
				return (this.crud === undefined || this.crud.includes('d')) && this.permission.delete
			} else if (this.isPortal) {
				return (this.crud === undefined || this.crud.includes('d'))
			}
		}
	},
	beforeDestroy () {
		if (this.gridOptions && this.gridOptions.api) this.$bus.$off('resize', this.agGridResize)
	},
	methods: {
		showUserForm (data) {
			return new Promise((resolve, reject) => {
				currentUserFormPromise = resolve
				// data.onSubmit = resolve
				this.$root.$children[0].$refs.userForm.run(data)
			})
		},
		submitUserForm (data) {
			currentUserFormPromise(data)
		},
		loadScript (src) {
			return new Promise((resolve, reject) => {
				// if ($("script[src='" + src + "']").length === 0) {
				const script = document.createElement('script')
				script.onload = resolve
				script.onerror = reject
				script.src = src
				document.body.appendChild(script)
				// } else {
					// resolve()
				// }
			})
		},
		validateInput (input) {
			if (!input || input.length < 5) return 'Required and Input has to be min length 5'
			return true
		},
		async copyToClipboard (rawData) {
			try {
				this.copying = true
				const str = typeof rawData === 'string' ? rawData : JSON.stringify(rawData, null, '\t')
				await navigator.clipboard.writeText(str)
				this.$message.success('Copied to clipboard')
			} catch (err) {
				this.axiosErr('Failed to copy to clipboard')
			} finally {
				this.copying = false
			}
		},
		auditComment () {
			if (this.$store.state.setup.account.config && !this.$store.state.setup.account.config._audit_comment) return Promise.resolve(false)
			return this.$prompt('', 'Audit Comment', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				inputType: 'text-area',
				inputValidator: this.validateInput
			}).then(({ value }) => value)
			.catch(() => {
				return Promise.reject(new Error('audit_cancel'))
			})
		},
		gridSearch (row, searchText) {
			if (row && searchText && typeof row === 'object' && typeof searchText === 'string') {
				searchText = ('' + searchText).toLowerCase()
				const searchArray = Array.isArray(searchText) ? searchText : searchText.split(',').map(item => item.trim())
				for (const col in row) {
					if (col.charAt(0) !== '$' && hasOwnProperty.call(row, col) && this.gridSearch(row[col], searchArray)) {
						return true
					}
				}
				return false
			}
			const searchArray = Array.isArray(searchText) ? searchText : searchText.split(',').map(item => item.trim())
			return searchArray.filter(search => {
				return ('' + row).toLowerCase().indexOf(search) > -1
			}).length > 0
			// return ('' + row).toLowerCase().indexOf(searchText) > -1
		},
		docLink (link) {
			const help = link || ''
			window.open(`https://docs.connexcs.com/${help}`, '_blank')
		},
		redirect (path) {
			if (event && event.ctrlKey) return window.open('/#' + path, '_blank')
			this.$router.push({ path })
		},
		onNewTab (link) {
			window.open(`https://${link}`, '_blank')
		},
		goBack (path) {
			window.history.length > -1 ? this.$router.go(-1) : this.$router.push({ path })
		},
		deleteReq (apiName, rows, queryData, opts) {
			const errorArr = []
			// var successArr = []
			return axios.all(rows.map((row) => {
				let qry = `${this._api}/${apiName}/${row.id}`
				if (Object.keys(queryData).length > 0) qry += '?' + Object.entries(queryData).map(([k, v]) => `${k}=${v}`).join(',')
				return axios.delete(qry, opts)
					.then(r => {
						// successArr.push(row.id)
						return r
					})
					.catch(err => {
						errorArr.push({ id: row.id, err })
						return Promise.resolve()
					})
			}))
			.then(res => {
				const obj = {}
				if (!errorArr.length) return Promise.resolve(res)
				errorArr.forEach(({ id, err }) => {
					let message = err.message
					if (err.response && err.response.data && err.response.data.message) message = err.response.data.message
					if (!obj[message]) obj[message] = { message, ids: [] }
					obj[message].ids.push(id)
				})
				const msg = 'The following errors have occured:\n' + Object.values(obj)
					.map(({ message, ids }) => message + (ids.length > 1 ? ' x' + ids.length : ''))
					.join('\n')
				const newErr = new Error(msg)
				newErr.details = obj
				return Promise.reject(newErr)
			})
		},
		cxDelete (apiName, rows, queryData = {}) {
			return this.auditComment()
				.then(comment => {
					const opts = {}
					if (comment) opts.data = { _audit_comment: comment }
					return this.$confirm(`Do you wish to delete the following ${rows.length} items?`, 'Warning', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: 'OK',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}).then(() => {
						return this.deleteReq(apiName, rows, queryData, opts)
					})
				})
				.then((res) => {
					this.$message.success('Deleted successfully')
					return Promise.resolve()
				}).catch(err => {
					if (err !== 'cancel') this.axiosErr(err)
				})
				.finally(() => {
					if (this.gridOptions && this.gridOptions.api) this.gridOptions.api.deselectAll()
					// if (this.selectedRows) this.selectedRows.length = 0
					if (this.$refs.table) this.$refs.table.clearSelection() // for el-table grid
				})
		},
		whenVuexReady () {
			let forceRefresh = false
			const stores = [...arguments]
			if (stores[stores.length - 1] === true || stores[stores.length - 1] === false) forceRefresh = stores.pop()

			return Promise.all(Array.from(stores).map(vuexStore => {
				if (vuexStore === 'self') {
					if (!forceRefresh && Object.keys(this.$store.state.setup.self).length) return Promise.resolve(this.$store.state.setup.self)
					/* eslint no-new: "off" */
					let resolve1 = null
					let reject1 = null
					const promise = new Promise((resolve, reject) => {
						resolve1 = resolve
						reject1 = reject
					})
					if (this.$store.state.setup.pending.self && this.$store.state.setup.promise.self) return this.$store.state.setup.promise.self
					this.$store.commit('promiseSetup', promise)
					return this.$store.dispatch('getSelf').then((res) => {
						resolve1(res && res.data)
						return res && res.data
					}).catch(e => {
						reject1(e)
						throw e
					})
				} else if (vuexStore === 'account') {
					if (!forceRefresh && Object.keys(this.$store.state.setup.account).length) return Promise.resolve(this.$store.state.setup.account)
					return this.$store.dispatch('getAccount').then((res) => res && res.data)
				} else if (vuexStore === 'vuexAnalyticsCustomer') {
					if (!forceRefresh && Object.keys(this.$store.state.vuexAnalytics.customer).length) return Promise.resolve(this.$store.state.vuexAnalytics.customer)
					return this.$store.dispatch('getVuexAnalyticsCustomer')
				} else if (vuexStore === 'vuexAnalyticsCarrier') {
					if (!forceRefresh && Object.keys(this.$store.state.vuexAnalytics.carrier).length) return Promise.resolve(this.$store.state.vuexAnalytics.carrier)
					return this.$store.dispatch('getVuexAnalyticsCarrier')
				} else {
					const plural = vuexStore + 's'
					const fnName = 'list' + plural.charAt(0).toUpperCase() + plural.substr(1)
					if (!forceRefresh && this.$store.state[vuexStore] && this.$store.state[vuexStore][plural] && this.$store.state[vuexStore][plural].length > 0) return Promise.resolve(this.$store.state[vuexStore][plural])
					return this.$store.dispatch(fnName).then((res) => res && res.data)
				}
			}))
		},
		axiosErr (err, type = 'error') {
			let message = err
			if (err && err.message) message = err.message
			if (message === 'audit_cancel') return
			if (err.response && err.response.data && err.response.data.message) message = err.response.data.message
			if (err.data && err.data.message) message = err.data.message
			if (err.stack) console.log(err.message, err.stack)
			if (message && typeof message === 'string' && message.includes('\n')) {
				const errorType = type.charAt(0).toUpperCase() + type.slice(1)
				this.$alert(message.replace('\n', '<br/>'), errorType, { type, dangerouslyUseHTMLString: true })
			} else {
				this.$message({ message, type })
			}
		},
		dynamicFormDefault (schema) {
			return schema
				.reduce((obj, col) => {
					let curObj = obj
					col.name.split('.').forEach((part, index, array) => {
						if (index + 1 === array.length) {
							curObj[part] = col.defaultVal
						} else {
							if (!curObj[part]) curObj[part] = {}
							curObj = curObj[part]
						}
					})
					return obj
				}, {})
		},
		formDefault (schema) {
			return schema.reduce((obj, col) => {
				let curObj = obj
				col.name.split('.').forEach((part, index, array) => {
					if (index + 1 === array.length) {
						curObj[part] = col.default
					} else {
						if (!curObj[part]) curObj[part] = {}
						curObj = curObj[part]
					}
				})
				return obj
			}, {})
		},
		getFormRule (rule) {
			return FormValidationRules[rule] ? FormValidationRules[rule] : rule
		},
		dynamicformValidate (col) {
			return col.rules.filter(rule => rule !== 'required').map(this.getFormRule)
		},
		formValidate (schema) {
			return schema.filter(col => col.rules && col.rules.length).map(col => {
				col.rules = col.rules.map(this.getFormRule)
				return col
			})
			.reduce((obj, col) => {
				obj[col.name] = col.rules
				return obj
			}, {})
		},
		async globalGetDataById (apiName, params = {}) {
			try {
				this.pending = true
				this.error = false
				const { data } = await axios.get(`${this._api}/${apiName}`, { params })
				return data
			} catch (err) {
				this.error = err
				this.axiosErr(err)
			} finally {
				this.pending = false
			}
		},
		asyncValidate (obj) {
			return new Promise((resolve, reject) => obj.validate(v => v ? resolve() : reject(new Error('Form Validation Failed'))))
		},
		async globalSave (scope, apiName, auditComment = false) {
			try {
				await this.asyncValidate(this.$refs[scope])
				const comment = auditComment && await this.auditComment()
				let tempData = Object.assign({}, this.formData)
				if (this.prepareSaveData) tempData = this.prepareSaveData(tempData)
				if (comment) tempData._audit_comment = comment
				const httpAction = (tempData.id ? axios.put : axios.post)
				const id = tempData.id || ''
				this.pending = true
				const res = await httpAction(`${this._api}/${apiName}/${id}`, tempData)
				this.$message.success((id ? 'Updated' : 'Saved') + ' successfully')
				if (this.isDialogVisible) {
					this.$emit('closedialog')
					this.isDialogVisible = false
				}
				return res
			} catch (e) {
				if (e.message !== 'Form Validation Failed') {
					this.axiosErr(e)
					throw e
				}
				this.errorMessage = 'Either required fields are not filled in or validation is failed.'
				this.axiosErr(e)
				throw e
			} finally {
				this.pending = false
			}
		},
		findInParent (property, parent = false, x = 0) {
			if (parent === false) parent = this.$parent
			if (parent[property]) return parent[property]
			if (!parent.$parent) return false
			return this.findInParent(property, parent.$parent, x + 1)
		},
		sleep (timeout) {
			return new Promise((resolve, reject) => {
				setTimeout(resolve, timeout)
			})
		},
		applyDebounce (fn, timeout = 200) {
			if (this.debounceMap[fn]) {
				clearTimeout(this.debounceMap[fn])
				delete this.debounceMap[fn]
			}
			this.debounceMap[fn] = setTimeout(() => {
				delete this.debounceMap[fn]
				fn()
			}, timeout)
		},
		parseStringifyFn (obj) {
			const fnTemp = {}

			let idx = 0
			const jsonText = JSON.stringify(
				obj,
				(key, value) => {
					if (typeof value === 'function') {
						fnTemp[++idx] = value
						return `FUNCTION:${idx}`
					}
					return value
				}
			)

			const map2 = JSON.parse(
				jsonText,
				(key, value) => {
					if (typeof value === 'string') {
						const res = /FUNCTION:(\d+)/.exec(value)
						if (res) return fnTemp[res[1]]
					}
					return value
				}
			)
			return map2
		}
	}
}
