<template>
	<v-container class="visual-voicemail">
		<h1>Visual Voicemail</h1>
		<div id="waveform" style="margin: 2rem 0 3rem;"></div>
		<v-data-table :headers="parsedVoicemailList.headers" :items="parsedVoicemailList.voicemailConfig" :items-per-page="5" class="elevation-1">
			<template v-slot:top>
				<v-btn v-if="!waveSurfer?.isPlaying()" plain x-large  style="padding-left: 0.35rem;">
					<v-icon color="green" x-large @click="waveSurfer?.play()">mdi-play-outline</v-icon>
					&nbsp;&nbsp;{{ voicemailPlaiying }}
				</v-btn>
				<v-btn v-else plain x-large style="padding-left: 0.35rem;">
					<v-icon  color="red" @click="waveSurfer?.pause()" x-large>mdi-pause-circle-outline</v-icon>
					&nbsp;&nbsp;{{ voicemailPlaiying }}
				</v-btn>
			</template>
			<!-- eslint-disable -->
			<template v-slot:item.fileUrl="{item}">
				<v-btn icon medium>
					<v-icon @click="() => {playSelected(item)}">mdi-play-outline</v-icon>
				</v-btn>
				<v-btn icon medium class="ml-3">
					<v-icon @click="() => {downloadSelected(item)}">mdi-download-outline</v-icon>
				</v-btn>
				<!-- <v-btn icon medium class="ml-3"> DELETING LOGIC
					<v-icon color="red" @click="deleteSelected(item)">mdi-delete-outline</v-icon>
				</v-btn>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
					<v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
					</v-card>
				</v-dialog> -->
			</template>
			<!-- <template v-slot:no-data>
				<v-btn
				color="primary"
				@click="initialize"
				>
					Reset
				</v-btn>
			</template> -->
			<!-- eslint-enable -->
		</v-data-table>
</v-container>
</template>
<style></style>

<script>
import WaveSurfer from 'wavesurfer.js'
import {mapActions, mapState} from 'vuex'
// import Axios from 'axios'

export default {
	data () {
		return {
			waveSurfer: null,
			dialogDelete: false,
			parsedVoicemailList: { headers: [], voicemailConfig: []},
			voicemailPlaiying: '',
			deletedMail: [],
			indexToDelete: -1,
			dataLoaded: false
		}
	},
	computed: {
		...mapState({
			voicemail: state => state.voicemail,
			uuid: state => state.uuid
		}),
		voicemailList () {
			if (!this.voicemail || this.voicemail.constructor !== Array) return { headers: [], voicemailConfig: [] }
			const voicemailConfig = this.voicemail.map((mail) => {
				const created = this.$moment.unix(mail.ts).format('MMM Do YYYY - h:mm A')
				if (window) window.tx = this.$moment
				return { id: mail.id, niceId: `${mail.id.substr(0, 15)} ...`, created, duration: this.duration(mail.message_len), fileUrl: `/api/softphone/voicemail/${mail.id}.wav?uuid=${this.uuid}`, actions: 'actions', cid_number: mail.cid_number }
			})
			return {
				headers: [
					{ text: 'Caller', value: 'cid_number'},
					{ text: 'Duration', value: 'duration' },
					{ text: 'Created', value: 'created' },
					{ text: 'Actions', value: 'fileUrl' }
				],
				voicemailConfig
			}
		}
	},
	async mounted () {
		if (!document.querySelector('#waveform')) return
		await this.getVoicemail()
		if (this.voicemail.headers?.length && this.voicemail.voicemailConfig?.length) this.dataLoaded = true
		this.waveSurfer = WaveSurfer.create({
			container: '#waveform',
			waveColor: 'violet',
			progressColor: 'purple'
		})
		this.waveSurfer.load(this.parsedVoicemailList.voicemailConfig[0].fileUrl)
		this.voicemailPlaiying = this.parsedVoicemailList.voicemailConfig[0].niceId
	},
	beforeUnmount () {
		this.waveSurfer.unAll()
		this.waveSurfer.pause()
	},
	watch: {
		voicemailList (newVal) {
			this.parsedVoicemailList = newVal
		}
	},
	methods: {
		...mapActions(['getVoicemail']),
		duration (value) {
			if (value === undefined || value === null) return '-'
			const parsedValue = parseFloat(value)
			if (!parsedValue) return '0 min'
			if (parsedValue > 0 && parsedValue < 1) return (Math.round(parsedValue * 1000)) + ' ms'
			if (parsedValue < 60) return parsedValue.toFixed(2) + ' sec'
			const min = Math.floor(parsedValue / 60)
			let sec = (parsedValue - (min * 60)) % 60
			sec = sec?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 4})
			return min.toLocaleString() + ' min ' + sec + ' sec'
		},
		async playSelected (voicemail) {
			if (!voicemail) return
			if (this.waveSurfer.isPlaying) this.waveSurfer.pause()
			await this.waveSurfer.load(voicemail.fileUrl)
			this.voicemailPlaiying = voicemail.niceId
			this.waveSurfer.on('ready', () => {
				this.waveSurfer.play()
				this.waveSurfer.unAll()
			})
		},
		downloadSelected (voicemail) {
			return Object.assign(document.createElement('a'), { href: voicemail.fileUrl, target: '_blank' }).click()
		}
		// deleteSelected (vm) {
		// 	this.indexToDelete = this.parsedVoicemailList.voicemailConfig.indexOf(vm)
		// 	this.dialogDelete = true
		// },
		// async deleteItemConfirm () {
		// 	if (!this.indexToDelete) this.closeDelete()
		// 	try {
		// 		await Axios.delete(`/api/softphone/voicemail/${this.parsedVoicemailList.voicemailConfig[this.indexToDelete].id}?uuid=${this.uuid}`)
		// 	} catch (e) {
		// 		return this.throwErr(e)
		// 	}
		// 	this.parsedVoicemailList.voicemailConfig.splice(this.indexToDelete, 1)
		// 	// Load the next voicemail in the list
		// 	this.waveSurfer.load(this.parsedVoicemailList.voicemailConfig[this.indexToDelete].fileUrl)
		// 	this.voicemailPlaiying = this.parsedVoicemailList.voicemailConfig[this.indexToDelete].niceId
		// 	this.throwErr('Voicemail Deleted')
		// 	this.closeDelete()
		// },
		// closeDelete () {
		// 	this.dialogDelete = false
		// 	this.$nextTick(() => {
		// 		this.indexToDelete = -1
		// 	})
		// }
	}

}
</script>
