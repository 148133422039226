<script>
import CompactElementUi from './Compact-Element-Ui.vue'
import GenerateDialog from '../GenerateDialog.vue'
import GenerateColItem from './GenerateColItem.vue'
import GenerateTabItem from './GenerateTabItem.vue'
import GenerateReport from './GenerateReport.vue'
import GenerateInline from './GenerateInline.vue'
import GenerateCollapse from './GenerateCollapse.vue'

export default {
	extends: GenerateDialog,
	components: {
		GenerateColItem,
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateInline,
		GenerateReport,
		GenerateTabItem,
		GenerateCollapse
	},
	mixins: [CompactElementUi]
}
</script>
