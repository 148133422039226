import '@babel/polyfill'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './plugins/filters'
import mixinCommon from './common/src/mixins/mixins.js'
import mixin from './mixins.js'
import { ValidationProvider } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueI18n from 'vue-i18n'
// import axios from 'axios'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
// AG-Grid styling for the compact component
import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-balham.css'
import '@/assets/css/custom.css'

import ServiceWorker from './registerServiceWorker'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		release: 'webphone@' + process.env.VUE_APP_GIT_HASH,
		dsn: 'https://b50f093005024f4e91d61e1cf85f19d9@sentry.io/1378631',
		integrations: [new VueIntegration({ Vue, attachProps: true })],
		beforeSend (event, hint) {
			// Check if it is an exception, and if so, show the report dialog
			if (hint.originalException.message === 'User Reported Error') {
				Sentry.showReportDialog({
					eventId: event.event_id,
					title: 'Report a problem',
					subtitle: ''
				})
			}
			return event
		},
		ignoreErrors: [
			'InvalidStateError: RTCPeerConnection is gone',
			'InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable'
		]
	})
}
Object.defineProperty(Vue.prototype, '$sentry', { get: () => Sentry })

const EventBus = new Vue()
Object.defineProperty(Vue.prototype, '$bus', { get: () => EventBus })
// Object.defineProperty(Vue.prototype, '$serviceWorker', { get: () => EventBus })

ServiceWorker.$bus = EventBus
// Global Filter Declaration
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})
Vue.use(require('vue-moment'))

// Vue.use(VeeValidate)
Vue.component('ValidationProvider', ValidationProvider)

Vue.mixin(mixinCommon)
Vue.mixin(mixin) // Local Mixins should take Precidence
Vue.config.productionTip = true
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

// axios.defaults.baseURL = 'https://softphone-api.connexcs.com/'
// axios.defaults.withCredentials = true

/* Implement Language Intertionalization */
Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'en', // set locale
	fallbackLocale: 'en',
	silentTranslationWarn: true
})

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app')
