/* eslint-disable no-console */
import { register } from 'register-service-worker'
const pushPublicKey = 'BGeixNEGVqQMBczajmaqgZ0wzFpbYRWg4LrazC_PMEfvYqiXGP0xeKY_C9uWzKFqs0qongybdZkuqgJI7R43iFs'
const registerServiceWorker = {
	$bus: {}
}

function urlBase64ToUint8Array (base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4)
	const base64 = (base64String + padding)
		// .replace(/\-/g, '+')
		.replace(/-/g, '+')
		.replace(/_/g, '/')

	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

if (process.env.NODE_ENV === 'production') {
	register('service-worker.js', {
		ready () {
			console.log(
			'App is being served from cache by a service worker.\n' +
			'For more details, visit https://goo.gl/AFskqB'
			)
		},
		registered (registration) {
			registerServiceWorker.registration = registration
			// registration.getNotifications(options).then(function(notifications) {
			// do something with your notifications
			// })
			// console.log('Content has been cached for offline use.', registration)
			return registration.pushManager.getSubscription()
			.then(async function (subscription) {
				if (subscription) return subscription
				// const response = await fetch('https://cp.ramu.connexcs.com/api/cp/web-push/key')
				// const vapidPublicKey = await response.text()
				// const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
				const convertedVapidKey = urlBase64ToUint8Array(pushPublicKey)
				return registration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: convertedVapidKey
				})
			})
			.then(subscription => {
				registerServiceWorker.$bus.$emit('pushRegistration', JSON.parse(JSON.stringify(subscription)))
				// this.$bus.$emit('pushRegistration', subscription)
				registerServiceWorker.subscription = subscription
				// fetch('https://cp.ramu.connexcs.com/api/cp/web-push/register', {
					// method: 'post',
					// headers: {
						// 'Content-type': 'application/json'
					// },
					// body: JSON.stringify({ subscription })
				// })
			})
			.catch(err => {
				console.error('Service Worker err ', err)
			})
		},
		cached (registration) {},
		updatedfound () {},
		updated () {},
		offline () {},
		error (error) {
			console.error('Error during service worker registration:', error)
		}
	})
}

export default registerServiceWorker
