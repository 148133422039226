<template>
  <el-row
    :class="{
      [element.options && element.options.customClass]: element.options && element.options.customClass?true: false
    }"
    :type="element.options.flex ? 'flex' : ''"
    :gutter="element.options.gutter || 0"
    :justify="element.options.justify"
    :align="element.options.align"
    v-if="isSubform ? display[element.model] && !subHideFields[rowIndex][element.model] : display[element.model]"
  >

    <el-col v-for="(item, index) in element.columns" :key="index"
      :span="item.options ? getColMD(item.options) : item.span"
      :xs="item.options ? getColXS(item.options) : item.span"
      :sm="item.options ? getColSM(item.options) : item.span"
      :md="item.options ? getColMD(item.options) : item.span"
      :lg="item.options ? getColMD(item.options) : item.span"
      :xl="item.options ? getColMD(item.options) : item.span"
      :offset="item.options ? item.options.offset : 0"
      :push="item.options ? item.options.push : 0"
      :pull="item.options ? item.options.pull : 0"
      :class="{
        [item.options && item.options.customClass]: item.options && item.options.customClass?true: false
      }"
    >
      <template v-for="col in item.list">
        <generate-col-item
          v-if="col.type === 'grid'"
          :key="col.key"
          :model="dataModels"
          :rules="rules"
          :element="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-col-item>

        <generate-tab-item
          v-else-if="col.type === 'tabs'"
          :key="col.key"
          :model="dataModels"
          :rules="rules"
          :element="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-tab-item>

        <generate-collapse
          v-else-if="col.type === 'collapse'"
          :key="col.key"
          :model="dataModels"
          :rules="rules"
          :element="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-collapse>

        <generate-report
          v-else-if="col.type === 'report'"
          :key="col.key"
          :model="dataModels"
          :rules="rules"
          :element="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-report>

        <generate-inline
          v-else-if="col.type === 'inline'"
          :key="col.key"
          :model="dataModels"
          :rules="rules"
          :element="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-inline>

        <generate-form-item
          v-else
          :name="col.key"
          :key="col.key"
          :models="dataModels"
          :rules="rules"
          :widget="col"
          :remote="remote"
          :blanks="blanks"
          :display="display"
          :sub-hide-fields="subHideFields"
          :sub-disabled-fields="subDisabledFields"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="formComponent"
          :is-subform="isSubform"
          :row-index="rowIndex"
          :sub-name="subName"
          :is-dialog="isDialog"
          :dialog-name="dialogName"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-form-item>
      </template>
    </el-col>
  </el-row>
</template>

<script>
import GenerateTabItem from './GenerateTabItem'
import GenerateInline from './GenerateInline'
import GenerateReport from './GenerateReport'
import GenerateCollapse from './GenerateCollapse'

export default {
	name: 'generate-col-item',
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateTabItem,
		GenerateInline,
		GenerateReport,
		GenerateCollapse
	},
	props: ['element', 'model', 'rules', 'remote', 'blanks', 'display', 'edit', 'remoteOption', 'platform', 'preview', 'containerKey', 'dataSourceValue', 'eventFunction', 'printRead', 'formComponent', 'isSubform', 'rowIndex', 'subName', 'subHideFields', 'subDisabledFields', 'isDialog', 'dialogName'],
	data () {
		return {
			dataModels: this.model
		}
	},
	methods: {
		getColXS (options) {
			if (this.preview) {
				if (this.platform === 'pc') {
					return options.md
				}
				if (this.platform === 'pad') {
					return options.sm
				}
				if (this.platform === 'mobile') {
					return options.xs
				}
			} else {
				return options.xs
			}
		},
		getColSM (options) {
			if (this.preview) {
				if (this.platform === 'pc') {
					return options.md
				}
				if (this.platform === 'pad') {
					return options.sm
				}
				if (this.platform === 'mobile') {
					return options.xs
				}
			} else {
				return options.sm
			}
		},
		getColMD (options) {
			if (this.preview) {
				if (this.platform === 'pc') {
					return options.md
				}
				if (this.platform === 'pad') {
					return options.sm
				}
				if (this.platform === 'mobile') {
					return options.xs
				}
			} else {
				return options.md
			}
		}
	},
	watch: {
		model: {
			deep: true,
			handler (val) {
				this.dataModels = val
			}
		}
	}
}
</script>
