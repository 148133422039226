<template>
  <div :style="{width: data.config && data.config.width}" class="fm-form" :class="'fm-'+formStyleKey">
    <el-form :ref="formRef"
      :key="formKey"
      v-if="formShow"
      :class="{
        [data.config && data.config.customClass]:  (data.config && data.config.customClass) ? true : false,
        'no-label-form': data.config && (data.config.labelWidth === 0)
      }"
      :size="formSize"
      :model="models"
      :rules="rules"
      :label-position="data.config && data.config.labelPosition"
      :disabled="!edit"
      :label-width="data.config && data.config.labelWidth + 'px'">
      <template v-for="item in data.list">
        <generate-col-item
          v-if="item.type === 'grid'"
          :key="item.key"
          :model="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-col-item>

        <generate-tab-item
          v-else-if="item.type === 'tabs'"
          :key="item.key"
          :model="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-tab-item>

        <generate-collapse
          v-else-if="item.type === 'collapse'"
          :key="item.key"
          :model="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-collapse>

        <generate-report
          v-else-if="item.type === 'report'"
          :key="item.key"
          :model="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-report>

        <generate-inline
          v-else-if="item.type === 'inline'"
          :key="item.key"
          :model="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-inline>

        <generate-dialog
          v-else-if="item.type === 'dialog'"
          :key="item.key"
          :models.sync="models"
          :rules="rules"
          :element="item"
          :remote="remote"
          :blanks="blanks"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
          :component-instance="instanceObject"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-dialog>

        <generate-form-item
          v-else
          :key="item.key"
          :models="models"
          :rules="rules"
          :widget="item"
          :remote="remote"
          :blanks="blanks"
          :display="displayFields"
          @input-change="onInputChange"
          :edit="edit"
          :remote-option="remoteOption"
          :platform="platform"
          :preview="preview"
          :container-key="containerKey"
          :data-source-value="dataSourceValue"
          :event-function="eventFunction"
          :print-read="printRead"
          :form-component="$refs[formRef]"
        >
          <template v-slot:[blank.name]="scope" v-for="blank in blanks">
            <slot :name="blank.name" :model="scope.model"></slot>
          </template>
        </generate-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
/* eslint no-eval: "off" */
/* eslint no-new-func: "off" */
import GenerateColItem from './GenerateColItem'
import GenerateTabItem from './GenerateTabItem'
import GenerateReport from './GenerateReport'
import GenerateInline from './GenerateInline'
import GenerateCollapse from './GenerateCollapse.vue'
import GenerateDialog from './GenerateDialog.vue'
import {updateStyleSheets, splitStyleSheets, clearStyleSheets} from '../util/index.js'
import { EventBus } from '../util/event-bus.js'
import _ from 'lodash'
import axios from 'axios'

/* https://jsdoc.app */

export default {
	name: 'fm-generate-form',
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateColItem,
		GenerateTabItem,
		GenerateReport,
		GenerateInline,
		GenerateCollapse,
		GenerateDialog
	},
	props: {
		data: {
			type: Object,
			default: () => ({
				list: [],
				config: {
					labelWidth: 100,
					labelPosition: 'right',
					size: 'small',
					customClass: '',
					ui: 'element',
					layout: 'horizontal'
				}
			})
		},
		remote: {
			type: Object,
			default: () => ({})
		},
		value: {
			type: Object,
			default: () => ({})
		},
		edit: {
			type: Boolean,
			default: true
		},
		printRead: {
			type: Boolean,
			default: false
		},
		remoteOption: {
			type: Object,
			default: () => ({})
		},
		preview: {
			type: Boolean,
			default: false
		},
		platform: {
			type: String,
			default: 'pc'
		},
		scriptforgeEndpoint: String,
		databaseEndpoint: String,
		queryEndpoint: String
	},
	data () {
		return {
			models: {},
			rules: {},
			blanks: [],
			displayFields: {},
			dataBindFields: [],
			generateShow: false,
			resetModels: {},
			formKey: Math.random().toString(36).slice(-8),
			formStyleKey: Math.random().toString(36).slice(-8),
			formValue: this.value,
			formShow: false,
			formRef: Math.random().toString(36).slice(-8) + 'Form',
			containerKey: Math.random().toString(36).slice(-8),
			dataSourceValue: [],
			eventFunction: {},
			instanceObject: {},
			dataSourceInterface: []
		}
	},
	computed: {
		formSize () {
			switch (this.data && this.data.config && this.data.config.size) {
			case 'large': return 'large'
			case 'default': return 'small'
			case 'small': return 'mini'
			default: return 'small'
			}
		}
	},
	created () {
		this._initForm()
	},
	mounted () {
		const _this = this

		EventBus.$on('on-change-' + this.containerKey, (value, field, containerKey) => {
			if (this.containerKey === containerKey) {
				_this.setData({
					[field]: value
				})
			}

			_this.$emit('on-change', field, value, _this.models)
			_this.$emit(`on-${field}-change`, value)
		})
		this.$nextTick(() => {
			this.eventFunction.mounted && this.eventFunction.mounted()
		})
	},
	beforeDestroy () {
		const head = '.fm-' + this.formStyleKey + ' '
		clearStyleSheets(head)
		EventBus.$off('on-change-' + this.containerKey)
	},
	provide () {
		return {
			generateComponentInstance: this.generateComponentInstance,
			deleteComponentInstance: this.deleteComponentInstance,
			eventScriptConfig: this.data?.config?.eventScript || []
		}
	},
	methods: {
		_initForm () {
			this.formShow = false

			this.models = {}
			this.rules = {}
			this.blanks = []
			this.displayFields = {}
			this.dataBindFields = []
			this.resetModels = {}
			this.dataSourceValue = []
			this.eventFunction = {}
			this.dataSourceInterface = []
			if (Object.keys(this.data).length) {
				this.generateModel(this.data.list)
			} else {
				this.generateModel([])
			}

			this.resetModels = _.cloneDeep(this.models)

			this.models = {...this.models}

			this.formShow = true

			if (this.data.config && this.data.config.styleSheets) {
				const head = '.fm-' + this.formStyleKey + ' '

				updateStyleSheets(splitStyleSheets(this.data.config.styleSheets), head)
			}

			this.loadDataSource()

			this.loadEvents()

			this.$nextTick(() => {
				this.eventFunction.refresh && this.eventFunction.refresh()
			})
		},
		loadEvents () {
			if (this.data.config && this.data.config.eventScript) {
				for (let i = 0; i < this.data.config.eventScript.length; i++) {
					const currentScript = this.data.config.eventScript[i]
					this.eventFunction[currentScript.key] = (...args) => this.wrapAsyncError([currentScript.func], currentScript.key, ...args)
				}
			}
		},
		async wrapAsyncError (fnArgs, name, ...args) {
			/* eslint-disable */
			const AsyncFunction = (async function () {}).constructor
			try {
				const fn = new AsyncFunction(...fnArgs).bind(this)
				return await fn(...args)
			} catch (err) {
				if (this.$alert) {
					this.$alert(`<pre>Error: ${err.message}\nin Event Function ${name}</pre>`, err.message, {
						type: 'error',
						dangerouslyUseHTMLString: true
					})
				} else {
					/* TODO: Need to write an alert popup box for non-element-ui interfaces */
					this.throwErr(err.message)
					console.error(err)
				}
			}
			/* eslint-enable */
		},
		wrapError (fnArgs, name, ...args) {
			try {
				/* eslint-disable */
				const fn = Function(...fnArgs).bind(this)
				/* eslint-enable */
				return fn(...args)
			} catch (err) {
				if (this.$alert) {
					this.$alert(`<pre>Error: ${err.message}\nin Event Function ${name}</pre>`, err.message, {
						type: 'error',
						dangerouslyUseHTMLString: true
					})
				} else {
					/* TODO: Need to write an alert popup box for non-element-ui interfaces */
					console.error(err)
				}
			}
		},
		triggerEvent (eventName, args) {
			if (this.data.config && this.data.config.eventScript) {
				const eventScript = this.data.config.eventScript.find(item => item.name === eventName)

				if (eventScript) {
					return this.eventFunction[eventScript.key](args)
				}
			}
		},
		loadDataSource () {
			for (let i = 0; i < this.dataSourceInterface.length; i++) {
				const curRequest = this.dataSourceInterface[i]
				const requestObj = this.data.config.dataSource.find(item => item.key === curRequest.key)
				if (requestObj && requestObj.auto) {
					requestObj.name && this.sendRequest(requestObj.name, curRequest.args).then(data => {
						curRequest.fields.forEach(field => {
							const curKey = field + '.' + curRequest.key
							const sourceValue = this.dataSourceValue.find(item => item.key === curKey)

							if (sourceValue) {
								sourceValue.value = data
							} else {
								this.dataSourceValue.push({
									key: curKey,
									value: data
								})
							}
						})
					})
				}
			}
      // 处理需要初始化请求但没有进行绑定的数据源
      // if (this.data.config?.dataSource?.length > 0) {
        // for (let i = 0; i < this.data.config.dataSource.length; i++) {
          // let currentDataSource = this.data.config.dataSource[i]

          // if (currentDataSource.auto && this.dataSourceInterface.findIndex(item => item.key === currentDataSource.key) < 0) {
            // this.sendRequest(currentDataSource.name, {})
          // }
        // }
      // }
		},

		refreshFieldDataSource (field, args) {
			const curRequest = this.dataSourceInterface.find(item => item.fields.includes(field))

			if (curRequest) {
				const requestName = this.data.config.dataSource.find(item => item.key === curRequest.key)?.name
				requestName && this.sendRequest(requestName, {...curRequest.args, ...args}).then(data => {
					const curKey = field + '.' + curRequest.key
					const sourceValue = this.dataSourceValue.find(item => item.key === curKey)

					if (sourceValue) {
						sourceValue.value = data
					} else {
						this.dataSourceValue.push({
							key: curKey,
							value: data
						})
					}
				})
			}
		},
		/**
			* Access database for List(get all records), Read (get a record), Update (update a record), Delete (delete a record)
			* @function database
			* @params {string} name - Database name
			* @example
			* Get all the records from a database 'customer'
			* @function list
			* this.database('customer').list()
			* @returns {Array} all the records
			* @example
			* Create a record
			* @function create
			* @params {Object} data
			* var data = {name: 'XYZ', age: 24}
			* this.database('customer').create(data)
			* @returns success if created
			* @example
			* Read a record, by an id
			* @function read
			* @params {Number} id
			* var id = 24
			* this.database('customer').read(id)
			* @returns a matched record, example {id: 24, name: 'XYZ'}
			* @example
			* Update a record
			* @function update
			* @params {Object} data
			* var updatedData = {id: 24, name: 'XYZ-1'}
			* this.database('customer').update(updatedData.id, updatedData)
			* @returns success if created
			* @example
			* Delete a record by id
			* @function update
			* @params {Number} id
			* var id = 24
			* this.database('customer').delete(id)
			* @returns success if deleted
		*/
		database (name = '') {
			const sendRequest = this.sendRequest
			return {
				list (args = {}) {
					return sendRequest(name, args)
				},
				create (data = {}, args = {}) {
					return sendRequest(name, args, {method: 'POST', data})
				},
				read (id = '', args = {}) {
					return sendRequest(name, args, {appendUrl: `/${id}`})
				},
				update (id = '', data = {}, args = {}) {
					return sendRequest(name, args, {method: 'PUT', data, appendUrl: `/${id}`})
				},
				delete (id = '', args = {}) {
					return sendRequest(name, args, {method: 'DELETE', appendUrl: `/${id}`})
				}
			}
		},
		/**
			* Access scriptForge
			* @function scriptForge
			* @params {string} name - script forge name
			* @params {Object} data
			* @params {string} fn - function name
			* @params {Object} args - arguments
			* @returns Pending
		*/
		scriptForge (name = '', data = {}, fn = '', args = {}) {
			return this.sendRequest(name, args, {fn, data})
		},
		/**
			* Access query
			* @function query
			* @params {string} name - script forge name
			* @params {Object} params
			* @params {Object} args - arguments
			* @returns Pending
		*/
		query (name = '', params = {}, args = {}) {
			return this.sendRequest(name, args, params)
		},
		/**
			* send a request to GET, POST, PUT, DELETE
			* @function sendRequest
			* @params {string} name
			* @params {Object} args - arguments
			* @params {Object} extendOptions - extendOptions
			* @example
			* this.sendRequest('customer')
			* pending
		*/
		sendRequest (name, args = {}, extendOptions = {}) {
			return new Promise((resolve, reject) => {
				const currentDataSource = this.data.config.dataSource.find(item => item.name === name)

				if (currentDataSource) {
					let options = {
						method: currentDataSource.method,
						url: currentDataSource.url,
						headers: currentDataSource.headers,
						params: currentDataSource.params
					}
					if (currentDataSource.scriptforge_id) {
						options.method = 'POST'
						const fn = extendOptions.fn || currentDataSource.scriptforge_fn || 'main'
						delete extendOptions.fn
						options.url = `${this.scriptforgeEndpoint}/${currentDataSource.scriptforge_id}${!this.scriptforgeEndpoint.includes('softphone') ? '/run' : ''}?fn=${fn}`
					} else if (currentDataSource.database) {
						options.method = 'GET'
						options.url = `${this.databaseEndpoint}/${currentDataSource.database}` + (extendOptions.appendUrl || '')
						delete extendOptions.appendUrl
					} else if (currentDataSource.query) {
						options.method = 'GET'
						options.url = `${this.queryEndpoint}/${currentDataSource.query}/run`
						delete extendOptions.appendUrl
					}

					options = {...options, ...extendOptions}

          // 请求发送前处理函数
					if (currentDataSource.requestFunc) {
						// const requestDynamicFunc = Function('config', 'args', currentDataSource.requestFunc).bind(this)
						const requestDynamicFunc = (...args) => this.wrapError(['config', 'args', currentDataSource.requestFunc], name, ...args)
						options = requestDynamicFunc(options, args)
					}

					axios(options).then(res => {
						let data = res

						if (currentDataSource.responseFunc) {
							const dynamicFunc = Function('res', 'args', currentDataSource.responseFunc).bind(this)

							data = dynamicFunc(res.data, args)

							resolve(data)
						} else {
							resolve(res.data)
						}
					}).catch((error) => {
            // 请求错误处理函数
						if (currentDataSource.errorFunc) {
							const errorDynamicFunc = this.wrapError(['error', currentDataSource.errorFunc], name, ...args)
							errorDynamicFunc(error)
						}

						reject(error)
					})
				}
			})
		},
		generateSubformModel (subName, genList) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					genList[i].columns.forEach(item => {
						this.generateSubformModel(subName, item.list)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this.generateSubformModel(subName, item.list)
					})
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this.generateSubformModel(subName, item.list)
					})
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this.generateSubformModel(subName, column.list)
						})
					})
				} else if (genList[i].type === 'inline') {
					this.generateSubformModel(subName, genList[i].list)
				} else {
					if (genList[i].type === 'blank') {
						this.blanks.push({
							name: genList[i].model
						})
					}

          // 处理 rules
					this._generateRules(`${subName}.${genList[i].model}`, genList[i].rules)

          // 处理子表单中的DataSource
					this._generateDataSource(genList[i], `${subName}.${genList[i].model}`)
				}
			}
		},
		generateDialogModel (dialogName, genList) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					genList[i].columns.forEach(item => {
						this.generateDialogModel(dialogName, item.list)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this.generateDialogModel(dialogName, item.list)
					})
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this.generateDialogModel(dialogName, item.list)
					})
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this.generateDialogModel(dialogName, column.list)
						})
					})
				} else if (genList[i].type === 'inline') {
					this.generateDialogModel(dialogName, genList[i].list)
				} else {
					if (genList[i].type === 'blank') {
						this.blanks.push({
							name: genList[i].model
						})
					}

					if (genList[i].type === 'subform') {
						this.generateSubformModel(`${dialogName}.${genList[i].model}`, genList[i].list)
					}

					genList[i].tableColumns && genList[i].tableColumns.length && genList[i].tableColumns.forEach(item => {
						if (item.type === 'blank') {
							this.blanks.push({
								name: item.model
							})
						}

            // 处理 rules
						this._generateRules(`${dialogName}.${genList[i].model}.${item.model}`, item.rules)

            // 处理子表单中的DataSource
						this._generateDataSource(item, `${dialogName}.${genList[i].model}.${item.model}`)
					})

					this._generateRules(`${dialogName}.${genList[i].model}`, genList[i].rules)

          // 处理弹框中的DataSource
					this._generateDataSource(genList[i], `${dialogName}.${genList[i].model}`)
				}
			}
		},
		generateModel (genList) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					this.displayFields[genList[i].model] = !genList[i].options.hidden

					genList[i].columns.forEach(item => {
						this.generateModel(item.list)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this.generateModel(item.list)
					})

					this.displayFields[genList[i].model] = !genList[i].options.hidden
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this.generateModel(item.list)
					})

					this.displayFields[genList[i].model] = !genList[i].options.hidden
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this.generateModel(column.list)
						})
					})

					this.displayFields[genList[i].model] = !genList[i].options.hidden
				} else if (genList[i].type === 'inline') {
					this.generateModel(genList[i].list)

					this.displayFields[genList[i].model] = !genList[i].options.hidden
				} else {
					if (Object.keys(this.formValue).indexOf(genList[i].model) >= 0) {
						this.models[genList[i].model] = this.formValue[genList[i].model]
            // 处理老版本没有dataBind值的情况，默认绑定数据
						if ((Object.keys(genList[i].options).indexOf('dataBind') < 0 || genList[i].options.dataBind) && genList[i].key && genList[i].model) {
							this.dataBindFields.push(genList[i].model)
						}

						this.displayFields[genList[i].model] = !genList[i].options.hidden

						if (genList[i].type === 'blank') {
							this.blanks.push({
								name: genList[i].model
							})
						}
					} else {
						if (genList[i].type === 'blank') {
              // bound the default value
							this.models[genList[i].model] = genList[i].options.defaultType === 'String' ? '' : (genList[i].options.defaultType === 'Object' ? {} : [])
							if ((Object.keys(genList[i].options).indexOf('dataBind') < 0 || genList[i].options.dataBind) && genList[i].key && genList[i].model) {
								this.dataBindFields.push(genList[i].model)
							}
							this.displayFields[genList[i].model] = !genList[i].options.hidden

							this.blanks.push({
								name: genList[i].model
							})
						} else {
							this.models[genList[i].model] = genList[i].options.defaultValue
							if ((Object.keys(genList[i].options).indexOf('dataBind') < 0 || genList[i].options.dataBind) && genList[i].key && genList[i].model) {
								this.dataBindFields.push(genList[i].model)
							}
							this.displayFields[genList[i].model] = !genList[i].options.hidden
						}
					}

					if (genList[i].type === 'subform') {
						this.generateSubformModel(genList[i].model, genList[i].list)
					}

					if (genList[i].type === 'dialog') {
						this.generateDialogModel(genList[i].model, genList[i].list)
					}

					genList[i].tableColumns && genList[i].tableColumns.length && genList[i].tableColumns.forEach(item => {
						if (item.type === 'blank') {
							this.blanks.push({
								name: item.model
							})
						}

            // 处理 rules
						this._generateRules(`${genList[i].model}.${item.model}`, item.rules)

            // 处理子表单中的DataSource
						this._generateDataSource(item, `${genList[i].model}.${item.model}`)
					})

					this._generateRules(genList[i].model, genList[i].rules)

          // 处理DataSource
					this._generateDataSource(genList[i], genList[i].model)
				}
			}
		},
		_generateDataSource (element, model) {
			if (element.options.remoteType === 'datasource' && element.options.remoteDataSource) {
				this._setDataSourceInterface(model, element.options.remoteArgs, element.options.remoteDataSource)
			}
			if ((element.type === 'imgupload' || element.type === 'fileupload') && element.options.tokenType === 'datasource' && element.options.tokenDataSource) {
				this._setDataSourceInterface(model, element.options.remoteArgs, element.options.tokenDataSource)
			}
		},
		_generateRules (model, rules) {
			if (!this.rules[model]) this.rules[model] = []
			if (rules) {
				this.rules[model].push(...rules.map(im => {
					if (im.pattern) return {...im, pattern: eval(im.pattern)}
					if (im.func) {
						const validatorFunc = (...args) => this.wrapError(['rule', 'value', 'callback', im.func], name, ...args)

						return {...im, validator: validatorFunc}
					}
					return {...im}
				}))
			}
		},
		_setDataSourceInterface (field, args, key) {
			let argsObj
			if (typeof args === 'string') {
				argsObj = (this.wrapError(['"use strict";return (' + args + ')'], name, []))()
			} else {
				argsObj = args
			}

			const findCurInterfaceIndex = this.dataSourceInterface.findIndex(item => item.key === key && _.isEqual(item.args, argsObj))

			if (findCurInterfaceIndex >= 0) {
				this.dataSourceInterface[findCurInterfaceIndex].fields.push(field)
			} else {
				this.dataSourceInterface.push({
					key,
					args: argsObj,
					fields: [field]
				})
			}
		},
		_setDisabled (genList, fields, disabled) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					genList[i].columns.forEach(item => {
						this._setDisabled(item.list, fields, disabled)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this._setDisabled(item.list, fields, disabled)
					})
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this._setDisabled(item.list, fields, disabled)
					})
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this._setDisabled(column.list, fields, disabled)
						})
					})
				} else if (genList[i].type === 'inline') {
					this._setDisabled(genList[i].list, fields, disabled)
				} else {
					if (fields.indexOf(genList[i].model) >= 0) {
						this.$set(genList[i].options, 'disabled', disabled)
					}
				}
			}
		},
		_updateClassName (genList, fields, className, updateType) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					genList[i].columns.forEach(item => {
						this._updateClassName(item.list, fields, className, updateType)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this._updateClassName(item.list, fields, className, updateType)
					})
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this._updateClassName(item.list, fields, className, updateType)
					})
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this._updateClassName(column.list, fields, className, updateType)
						})
					})
				} else if (genList[i].type === 'inline') {
					this._updateClassName(genList[i].list, fields, className, updateType)
				} else {
					if (fields.indexOf(genList[i].model) >= 0) {
						if (updateType === 'add' && !genList[i].options.customClass.split(' ').includes(className)) {
							this.$set(genList[i].options, 'customClass', [...genList[i].options.customClass.split(' '), className].join(' '))
						}

						if (updateType === 'remove' && genList[i].options.customClass.split(' ').includes(className)) {
							const originArray = genList[i].options.customClass.split(' ')
							originArray.splice(originArray.findIndex(item => item === className), 1)
							this.$set(genList[i].options, 'customClass', originArray.join(' '))
						}
					}
				}
			}
		},
		_setOptions (genList, fields, opts) {
			for (let i = 0; i < genList.length; i++) {
				if (genList[i].type === 'grid') {
					genList[i].columns.forEach(item => {
						this._setOptions(item.list, fields, opts)
					})
				} else if (genList[i].type === 'tabs') {
					genList[i].tabs.forEach(item => {
						this._setOptions(item.list, fields, opts)
					})
				} else if (genList[i].type === 'collapse') {
					genList[i].tabs.forEach(item => {
						this._setOptions(item.list, fields, opts)
					})
				} else if (genList[i].type === 'report') {
					genList[i].rows.forEach(row => {
						row.columns.forEach(column => {
							this._setOptions(column.list, fields, opts)
						})
					})
				} else if (genList[i].type === 'inline') {
					this._setOptions(genList[i].list, fields, opts)
				} else {
					if (fields.indexOf(genList[i].model) >= 0) {
						Object.keys(opts).forEach(key => {
							this.$set(genList[i].options, key, opts[key])
						})
					}
				}
			}
		},
		/**
			* Validate Form Data
			* @function validate
			* @memberof page
			* @param {Object} fields - Object is key-value pairs, key being the field name
			* @returns {Promise<Boolean>} Validation Success or Failure
			* @example
			* this.validate(fields)
			* @example
			* this.validate()
			* @description Validates the form data or a key-value pair
		*/
		validate (fields) {
			return new Promise((resolve, reject) => {
				if (fields) {
					this.$refs[this.formRef].validateField(fields, (error) => {
						if (!error) {
							resolve()
						} else {
							reject(error)
						}
					})
				} else {
					this.$refs[this.formRef].validate((valid, error) => {
						if (valid) {
							resolve()
						} else {
							reject(error)
						}
					})
				}
			})
		},
		/**
			* Get Form Data From A Component
			* @function getData
			* @memberof page
			* @param {Boolean} [isValidate = true]
			* @returns {Promise<Object>} All of the form data
			* @example
			* Validate form data Object by default
			* try {
			*	var data = await this.getData()
			*	// if success returns validated data as an object {name: 'XYZ'}
			*} catch (err) {
			*	// returns  err message {Name is required}
			*}
			}
			* @example
			* var data = await this.getData(false)
			* // data contains {name: 'XYZ'}
			* @description To get form data
		*/
		getData (isValidate = true) {
			return new Promise((resolve, reject) => {
				if (isValidate) {
					this.$refs[this.formRef].validate(valid => {
						if (valid) {
							const resData = {}
							Object.keys(this.models).forEach(key => {
								if (this.dataBindFields.indexOf(key) >= 0) {
									resData[key] = this.models[key]
								}
							})
							resolve(JSON.parse(JSON.stringify(resData)))
						} else {
							reject(new Error(this.$t('fm.message.validError')).message)
						}
					})
				} else {
					const resData = {}
					Object.keys(this.models).forEach(key => {
						if (this.dataBindFields.indexOf(key) >= 0) {
							resData[key] = this.models[key]
						}
					})
					resolve(JSON.parse(JSON.stringify(resData)))
				}
			})
		},
		/**
		* Reset Form Fields
		* @function reset
		* @memberof page
		* @apiDescription Reset form fields
		* @example
		* this.reset();
		*/
		reset () {
			this.setData(_.cloneDeep(this.resetModels))

			this.$nextTick(() => {
				setTimeout(() => {
					this.$refs[this.formRef].clearValidate()
				})
			})
		},
		onInputChange (value, field) {
			this.$emit('on-change', field, value, this.models)
			this.$emit(`on-${field}-change`, value)
		},
		/**
		* Display/Show Fields
		* @function display
		* @memberof page
		* @param {Array} fields
		* @apiDescription Display/Show the fields
		* @example
		* var fields = ['name', 'age'];
		* this.display(fields);
		*/
		display (fields) {
			Object.keys(this.displayFields).forEach(key => {
				if (fields.indexOf(key) >= 0) {
					this.$set(this.displayFields, key, true)
				}
			})

			this.displayFields = {...this.displayFields}
		},
		/**
		* Hide Fields
		* @function hide
		* @memberof page
		* @param {Array} fields
		* @apiDescription Hide the fields
		* @example
		* var fields = ['name', 'age'];
		* this.hide(fields);
		*/
		hide (fields) {
			Object.keys(this.displayFields).forEach(key => {
				if (fields.indexOf(key) >= 0) {
					this.$set(this.displayFields, key, false)
				}
			})

			this.displayFields = {...this.displayFields}
		},
		/**
		* Disable Fields
		* @function disabled
		* @memberof page
		* @param {Array} fields
		* @apiDescription Disable the fields
		* @example
		* var fields = ['name', 'age'];
		* var disabled = true
		* this.disabled(fields, disabled);
		*/
		disabled (fields, disabled) {
			this._setDisabled(this.data.list, fields, disabled)
		},
		addClassName (fields, className) {
			this._updateClassName(this.data.list, fields, className, 'add')
		},
		removeClassName (fields, className) {
			this._updateClassName(this.data.list, fields, className, 'remove')
		},
		/**
			* Refresh
			* @function refresh
			* @description refreshs the data
			* @example
			* this.refresh()
		*/
		refresh () {
			this._initForm()
		},
		/**
		* Set Data
		* @function setData
		* @memberof page
		* @param {Object} Value
		* @apiDescription setData can be used to populate a page/form with information stored in a key:value object
		* @example
		* var myData = {name: 'Joe Blogs', age: 30};
		* this.setData(myData);
		 */
		setData (value) {
			Object.keys(value).forEach(item => {
				this.$set(this.models, item, value[item])
			})
		},
		/**
			* Get A Component
			* @function getComponent
			* @param {string} name - The component name
			* @returns {Object} - Component Object
			* @example
			* this.getComponent('dialog')
			* @description 'dialog' is the ID field in the 'Component Attribute' tab
		*/
		getComponent (name) {
			return this.instanceObject[name]
		},
		/**
			* Get Form Values From A Component
			* @function getValues()
			* @returns The Data
			* @example
			* this.getComponent('dialog').getValues()
			* @example
			* var dialog = this.getComponent('dialog')
			* dialog.getValues()
			* @description To get form data values from a component
		*/
		getValues () {
			return this.models
		},
		/**
			* Get A Value From An Object
			* @function getValue
			* @params {string} fieldName - Field Name
			* returns Value of the selected field
			* @example
			* var myData = {name: 'Joe Blogs', age: 30};
			* this.getValue('name')
			* @returns 'Joe Blogs'
		*/
		getValue (fieldName) {
			return this.models[fieldName]
		},
		/**
			* Set Rules For A Field
			* @function setRules
			* @params {string} field
			* @params {Array} rules
			* @example
			* var field = 'name'
			* var rules = [
			* { required: true, message: 'Name is requried' }
			* ]
			* this.setRules(field, rules)
		*/
		setRules (field, rules) {
			this.$set(this.rules, field, [...rules])

			this.$refs[this.formRef].clearValidate(field)
		},
		/**
			* Set Options pending
		*/
		setOptions (fields, options) {
			this._setOptions(this.data.list, fields, options)
		},
		generateComponentInstance (key, instance) {
			if (this.instanceObject[key]) {
				if (Array.isArray(this.instanceObject[key])) {
					this.instanceObject[key] = [...this.instanceObject[key], instance]
				} else {
					this.instanceObject[key] = [this.instanceObject[key], instance]
				}
			} else {
				this.instanceObject[key] = instance
			}
		},
		/**
			* Delete Component
			* @function deleteComponentInstance
			* @params {string} key
			* @example - Delete a Dialog Component
			* this.deleteComponentInstance('dialog')
			* @description Deletes the component if it exists
		*/
		deleteComponentInstance (key) {
			if (this.instanceObject[key]) {
				delete this.instanceObject[key]
			}
		},
		/**
			* Set setOptionData pending
		*/
		setOptionData (fields, data) {
			fields.forEach(field => {
				/* eslint-disable */
				const curRef = this.instanceObject[field]
				curRef?.$parent?.loadOptions(data)

				const mCurRef = this.instanceObject['m' + field]
				mCurRef?.$parent?.loadOptions(data)
				/* eslint-enable */
			})
		}
	}
}
</script>

<style lang="scss">
</style>
