<template>
	<div v-bind="bind && bind" v-on="on && on" class="d-flex dynamic-button">
		<div class="d-flex align-items-center" v-if="localPlacement === 'side_nav'">
			<v-list-item-action>
				<v-icon color="grey">mdi-{{data.icon ? data.icon : 'file-presentation-box'}}</v-icon>
			</v-list-item-action>
			<v-list-item-subtitle>{{data.name || data.label}}</v-list-item-subtitle>
		</div>
		<div v-if="localPlacement === 'agent'" class="d-flex">
			<v-list-item-icon>
				<v-icon v-if="data.icon.length" large :color="data.type && buttonTypeColors[data.type]">mdi-{{data.icon}}</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{data.label}}</v-list-item-title>
			</v-list-item-content>
		</div>
		<div v-if="localPlacement === 'footer_nav'" style="height: 100%;">
			<v-btn v-if="data.source !== 'button-builder'" color="blue darken-4" text :value="data.title" :to="data.url" :disabled="data.title === 'Dialpad' && $route.name === 'answerCall'">
				<span>{{data.title}}</span>
				<v-icon>{{data.icon}}</v-icon>
			</v-btn>
			<v-btn v-else color="blue darken-4" text :value="data.label" @click="dynamicButtonClick(data)">
				<span>{{data.label}}</span>
				<v-icon>mdi-{{data.icon}}</v-icon>
			</v-btn>
		</div>
	<!--	Form Result	-->
		<render-content-dialog ref="renderContentDialog" :buttonResultObj="buttonResultObj" :formResultObj="formResultObj" :title="`${activeForm.name ? activeForm.name : activeBtn.label} Response`"></render-content-dialog>
	<!--	Form -->
		<dynamic-form-dialog ref="dynamicFormDialog" v-if="true" :form="activeForm" :fill-data="dynamicFormObj" @submit="submitForm" @close="closeFormDialog"></dynamic-form-dialog>
	</div>
</template>
<style scoped>
	.align-items-center {
		align-items: center!important;
	}
</style>
<script>
import ActionsApi from '@/components/Actions-Api.vue'

export default {
	mixins: [ActionsApi],
	props: {
		data: {
			type: Object,
			default: () => { return {} },
			description: 'An object representing a dynamic button created by button builder.'
		},
		color: {
			type: String,
			description: 'The button color.'
		},
		bind: Object,
		on: Object
	}
}
</script>
