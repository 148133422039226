export default {
	nospace: { pattern: /^\S*$/, message: 'Space is not allowed' },
	required: { required: true, message: 'Required' },
	date: { type: 'date', message: 'Enter Valid Date' },
	digits: { pattern: /^\d*$/, message: 'Digits only' },
	digitsOptionalSign: { pattern: /^-?\d*$/, message: 'Digits, - sign is optional' },

	decimal: { pattern: /^\d*\.?\d*$/, message: 'Digits or Decimals only' },
	decimalOptionalSign: { pattern: /^-?\d*\.?\d*$/, message: 'Digits or Decimals, - sign is optional' },
	decimalUpto3Places: { pattern: /^\d*\.?\d{0,3}$/, message: 'Digits or Decimals only, Up to 3 decimal places' },

	max255: { pattern: /^1?[0-9]?[0-9]$|^2(([0-4][0-9])|5[0-5])$/, message: 'Min 1 and max value 255 is allowed' },

	phone: { pattern: /^\d{9,13}$/, message: 'Digits Only Min 9 - Max 13' },
	// domain: { pattern: /^[a-z][a-z0-9-]{3,15}$/, message: 'Not a valid domain' },
	domain: { pattern: /^((?:([a-z0-9]\.|[a-z0-9][a-z0-9-]{0,61}[a-z0-9])\.)+)([a-z0-9]{2,63}|(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]))\.?$/, message: 'Not a valid domain' },
	hostNameDomain: {
		pattern: /^((?:([a-z0-9]\.|[a-z0-9][a-z0-9-]{0,61}[a-z0-9])\.)*)([a-z0-9]{2,63}|(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]))$/,
		message: 'Not a valid Host Name'
	},
	email: { type: 'email', message: 'Enter valid email address' },
	url: { type: 'url', message: 'Website format is not valid Ex: http://www.example.com' },
	ipFqdn: {
		pattern: /^(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?::[1-9][0-9]{1,4})?$|^(?:[a-z0-9][a-z0-9-]+\.)*[a-z]{2,25}(?::[1-9][0-9]{1,4})?$/,
		message: 'Must be in IP format or Valid domain name'
	},
	ipFqdnOrStar: {
		pattern: /^\*$|^(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?::[1-9][0-9]{1,4})?$|^(?:[a-z0-9][a-z0-9-]+\.)*[a-z]{2,25}(?::[1-9][0-9]{1,4})?$/,
		message: 'Must be in IP format or Valid domain name'
	},
	ipFqdnMultiline: {
		// Multiline
		// pattern: /^(?:(?:^|\n)(?:(?:(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?::[1-9][0-9]{1,4})?)|(?:(?:[a-z0-9][a-z0-9-]+\.)*[a-z]{2,25}(?::[1-9][0-9]{1,4})?)))+$/, // Atleast 2characters Eg: ac.alc.things.com
		pattern: /^(?:(?:^|\n)(?:(?:(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?::[1-9][0-9]{1,4})?)|(?:(?:[a-z0-9][a-z0-9-]*\.)*[a-z]{2,25}(?::[1-9][0-9]{1,4})?)))+$/, // Atleast 1character Eg: a.alc.things.com
		message: 'Must be in IP format or Valid domain name'
	},
	ipMultilineSubnet: {
		// Multiline
		pattern: /(?:(?:^|\n)(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])))+(\/[\d]{1,2})?$/,
		message: 'Must be in IP format'
	},
	ip: {
		pattern: /^(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))$/,
		message: 'Must be in IP format'
	},
	cidr: {
		pattern: /^(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(\/([0-9]|[1-2][0-9]|3[0-2]))$/,
		message: 'Must be in CIDR format'
	},
	ipMultiline: {
		// Multiline
		pattern: /^(?:(?:^|\n)(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])))+$/,
		message: 'Must be in IP format'
	}
}
