<template>
	<v-app>
		<link rel="stylesheet" type="text/css" href="/api/softphone/custom.css" />
		<v-navigation-drawer v-model="drawer" fixed app :right="navRight">
			<v-list>
				<v-list-item value="true" @click="toggleRegister">
					<v-list-item-action>
						<v-icon :color="state.session === 'Established' ? 'success' : status.color" id="nav_icon_toggle_register">{{status.icon}}</v-icon>
					</v-list-item-action>
					<v-list-item-title id="nav_btn_toggle_register">{{status.text}}</v-list-item-title>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item v-if="prov && prov.sipUser">
					<v-list-item-action>
						<v-icon color="grey">mdi-account-circle</v-icon>
					</v-list-item-action>
					<v-list-item-subtitle>{{prov.sipUser.username}}</v-list-item-subtitle>
				</v-list-item>
			<!-- Side Buttons -->
				<side-buttons v-if="prov && prov.sipUser"></side-buttons>
			<!-- Call STATS -->
				<call-stats-dialog></call-stats-dialog>
				<v-list-item v-if="!prov || !prov.flags || !prov.flags.includes('HIDE_UUID')">
					<v-list-item-action>
						<v-icon color="grey">mdi-sim</v-icon>
					</v-list-item-action>
					<v-list-item-subtitle>{{uuid.substr(0,8)}} / {{gitHash}}</v-list-item-subtitle>
				</v-list-item>
				<div v-if="!isStandalone">
					<v-list-item v-if="prov && prov.flags && prov.flags.includes('BALANCE')">
						<v-list-item-action>
							<v-icon color="grey">mdi-wallet</v-icon>
						</v-list-item-action>
						<v-list-item-subtitle>Balance: {{prov.balance | currency(prov.currency)}}</v-list-item-subtitle>
					</v-list-item>
					<v-list-item v-if="prov && prov.flags && prov.flags.includes('ACCEPT_PAYMENT')" @click="topup">
						<v-list-item-action>
							<v-icon color="success" id="nav_icon_topup">mdi-credit-card</v-icon>
						</v-list-item-action>
						<v-list-item-title id="nav_btn_topup">Topup</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="pwaInstallPrompt" @click="pwaInstall">
						<v-list-item-action>
							<v-icon color="success" id="nav_icon_install_app">mdi-apps</v-icon>
						</v-list-item-action>
						<v-list-item-title id="nav_btn_install_app">Install as an APP</v-list-item-title>
					</v-list-item>
<!--
				<v-list-item @click="pushNotification">
					<v-list-item-action>
						<v-icon color="success" id="nav_icon_push_notification">mdi-bell</v-icon>
					</v-list-item-action>
					<v-list-item-title id="nav_btn_push_notification">Test Push Notification</v-list-item-title>
				</v-list-item>
-->
					<v-list-item @click="unProvDialog = true">
						<v-list-item-action>
							<v-icon color="error" id="nav_icon_deactivate_account">mdi-close</v-icon>
						</v-list-item-action>
						<v-list-item-title id="nav_btn_deactivate_account">Deactivate Account</v-list-item-title>
					</v-list-item>
					<v-list-item @click="reportProblem">
						<v-list-item-action>
							<v-icon color="error" id="nav_icon_report_problem">mdi-bug</v-icon>
						</v-list-item-action>
						<v-list-item-title id="nav_btn_report_problem">Report Problem</v-list-item-title>
					</v-list-item>
				</div>
			</v-list>
		</v-navigation-drawer>

		<!-- Header & Right Menu Bar -->
		<v-app-bar app clipped-left v-if="navRight" :class="state.session === 'Established' ? 'green cursor-pointer' : ''" @click="state.session === 'Established' ? $router.push('dialpad') : ''">
			<v-tooltip v-if="updateAvailable && state.session === 'Idle'" bottom>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon color="warning" @click="reloadApp" id="right_menu_btn_reload_app">
						<v-icon id="right_menu_icon_reload_app">mdi-refresh-circle</v-icon>
					</v-btn>
				</template>
				<span>Update available click to Refresh</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon v-on="on" :color="status.color">{{status.icon}}</v-icon>
				</template>
				<span>{{status.text}}</span>
			</v-tooltip>
			<v-toolbar-title class="ml-1">{{title}}</v-toolbar-title>
			<v-btn v-if="state.isMute || state.isHeld" class="ml-1 warning pulse" ripple @click.stop="toggleMic" id="right_menu_btn_mic_off">
				<v-icon id="right_menu_icon_toggle_mic">mdi-microphone-off</v-icon>
				Mic Off
			</v-btn>
			<v-btn v-else-if="!hasDialpad" class="ml-1 warning" ripple @click.stop="toggleMic" id="right_menu_btn_mic_on">
				<v-icon id="right_menu_icon_toggle_mic">mdi-microphone</v-icon>
				Mic On
			</v-btn>
			<v-spacer></v-spacer>

			<div>
				<!--
					If Incoming - Answer And End Buttons
					If Answered - End And Mute Buttons
				-->
				<v-btn v-if="isAnswer" color="success" class="mr-2" ripple @click="answerCall" id="right_menu_btn_answer_call">
					<v-icon id="right_menu_icon_answer_call">mdi-phone-in-talk</v-icon> Answer
				</v-btn>
				<v-btn v-if="isEnd" color="error" class="mr-2" ripple @click="endCall" id="right_menu_btn_end_call">
					<v-icon id="right_menu_icon_end_call">mdi-phone-hangup</v-icon> End
				</v-btn>
			</div>

			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
		</v-app-bar>
		<!-- Header & Left Menu Bar -->
		<v-app-bar app v-else :class="state.session === 'Established' ? 'green cursor-pointer' : ''" @click="state.session === 'Established' ? $router.push('dialpad') : ''">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{title}}</v-toolbar-title>
			<v-spacer></v-spacer>

			<div>
				<!--
					If Incoming - Answer And End Buttons
					If Answered - End And Mute Buttons
				-->
				<v-btn v-if="isAnswer" color="success" class="mr-2" ripple @click="answerCall" id="left_menu_btn_answer_call">
					<v-icon id="left_menu_icon_answer_call">mdi-phone-in-talk</v-icon> Answer
				</v-btn>
				<v-btn v-if="isEnd" color="error" class="mr-2" ripple @click="endCall" id="left_menu_btn_end_call">
					<v-icon id="left_menu_icon_end_call">mdi-phone-hangup</v-icon> End
				</v-btn>
			</div>

			<v-tooltip v-if="updateAvailable && state.session === 'Idle'" bottom>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon color="warning" @click="reloadApp" id="left_menu_btn_reload_app">
						<v-icon id="left_menu_icon_reload_app">mdi-refresh-circle</v-icon>
					</v-btn>
				</template>
				<span>Update available click to Refresh</span>
			</v-tooltip>
			<v-btn v-if="state.isMute || state.isHeld" class="mr-2 warning pulse" ripple @click.stop="toggleMic" id="right_menu_btn_mic_off">
				<v-icon id="right_menu_icon_toggle_mic">mdi-microphone-off</v-icon>
				Mic Off
			</v-btn>
			<v-btn v-else-if="!hasDialpad" class="mr-2 warning" ripple @click.stop="toggleMic" id="right_menu_btn_mic_on">
				<v-icon id="right_menu_icon_toggle_mic">mdi-microphone</v-icon>
				Mic On
			</v-btn>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon v-on="on" :color="status.color">{{status.icon}}</v-icon>
				</template>
				<span>{{status.text}}</span>
			</v-tooltip>
		</v-app-bar>

		<v-content>
			<!--
				<p v-if="hasMicAccess === false">Explicit False (not falsy), Does not have mic access</p>
				onStatusChanged { session: [Idle|Initial|Establising|Established|Terminating|Terminated], register: [Initial|Registered|Unregistered|Terminated] }
			-->
			<sip-client
				ref="sipClient"
				:username="config.username"
				:password="config.password"
				:realm="config.realm"
				:displayName="config.displayName"
				:wsServer="config.wsServer"
				:cli="config.cli"
				:isTurn="prov && prov.flags && prov.flags.includes('TURN')"
				@onErr="err => $bus.$emit('err', err)"
				@onRinging="onRinging"
				@onStatusChanged="onStatusChanged"
				@onCdr="onCdr"
				:turn="prov.turn"
			></sip-client>
			<v-overlay v-if="!Object.keys(prov).length">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>

			<keep-alive>
				<router-view></router-view>
			</keep-alive>

			<!-- iframe starts -->
			<component v-show="$route.path === item.path" v-for="item in hasOpenIframesArr" :key="item.name" :is="item.name"></component>
			<!-- iframe ends -->
		</v-content>

		<v-bottom-navigation v-if="prov && prov.setup" class="justify-content-around" :value="true" color="#fff" fixed app grow>
			<div class="d-flex justify-content-around footer-menu" style="width: 75rem;">
				<dynamic-button class="d-flex align-items-center" v-for="(btn, idx) in bottomButtons" :key="idx" style="" :placement="btn.placement" :data="btn"/>
			</div>
		</v-bottom-navigation>

		<v-snackbar v-model="showErr">
			{{errMsg}}
			<v-btn color="pink" id="btn_show_err" text @click="showErr = false">Close</v-btn>
		</v-snackbar>

		<v-dialog v-if="prov" v-model="unProvDialog" persistent max-width="290">
			<v-card>
				<v-card-title class="headline">Are you sure to Unprovision?</v-card-title>
				<v-card-text>
					This will deactivate the account on this device and you may have to contact your provider if you wish to re-provision.
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="unProvDialog = false" id="btn_cancel_unprovision">Cancel</v-btn>
					<v-btn color="green darken-1" text @click="unProvision" id="btn_yes_unprovision">Yes, Continue</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-if="axiosCounter > 0" v-model="axiosDialog" hide-overlay persistent width="300">
			<v-card color="primary"	dark>
				<v-card-text>
					Please stand by
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="domInteractivityNeeded" hide-overlay persistent width="500">
			<v-card light>
				<v-card-title class="headline warning lighten-2">
					Incoming Call
				</v-card-title>
				<v-card-text class="mt-5">
					Your browser prevented auto answer on the call, please click to allow this call to be answered.
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text>Answer</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<provision-setup></provision-setup>
		<topup v-if="prov && prov.flags && prov.flags.includes('ACCEPT_PAYMENT') && prov.paymentMethods" ref="topupDialog"></topup>
		<compact-page v-if="prov && prov.setup" ref="page" hidden class="compact-page" scriptforge-endpoint="/api/softphone/scriptforge" page-endpoint="/api/softphone/page" page-url="compact-page"></compact-page>
	</v-app>
</template>
<style scoped>
.footer-menu {
	justify-content: space-between;
	overflow: auto;
	margin: 0 auto;
}
.footer-menu::-webkit-scrollbar {
    display: none;
}
</style>
<script>
import SipClient from '@/components/SIP-Client.vue'
import ProvisionSetup from '@/components/Provision-Setup-Dialog-(Signup).vue'
import Topup from '@/views/Topup-Dialog.vue'
import axios from 'axios'
import SideButtons from '@/components/Side-Buttons.vue'
import VisualVoicemail from '@/components/Visual-Voicemail.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import CallStatsDialog from '@/components/Call-Stats/Call-Stats-Dialog'
import CompactPage from '@/common/src/components/Page/Compact-Page.vue'
import Vue from 'vue'
import DynamicButton from '@/components/Dynamic-Button.vue'

export default {
	name: 'App',
	components: { SipClient, ProvisionSetup, Topup, SideButtons, CallStatsDialog, CompactPage, DynamicButton, VisualVoicemail },
	data () {
		return {
			isLoggedIn: false,
			domInteractivityNeeded: false,
			iframeComponentsArr: [], // Pages with iframe
			srcTimer: null,
			updateAvailable: false,
			gitHash: process.env.VUE_APP_GIT_HASH,
			userOffline: false,
			unProvDialog: false,
			drawer: false,
			axiosCounter: 0,
			axiosDialog: true,
			pwaInstallPrompt: null,
			errMsg: '',
			showErr: false,
			state: {
				registration: 'Unregistered',
				session: 'Inactive',
				direction: null,
				ringing: {},
				isMute: false,
				isHeld: false
			},
			togglingRegistration: false,
			config: {
				username: '',
				password: '',
				realm: '',
				displayName: '',
				wsServer: '',
				cli: ''
			},
			hasMicAccess: null,
			connectedWindow: {}
		}
	},
	computed: {
		...mapState({
			prov: state => state.prov,
			uuid: state => state.uuid,
			button: state => state.button
		}),
		bottomButtons () {
			if (!this.prov.menu) return []
			let btns = []
			if (this.button.constructor === Array) {
				btns = this.button.filter(btn => btn.area === 'webphone/footer_nav')
			}
			const list = [...this.prov.menu, ...btns].map(btn => {
				let placement = 'footer_nav'
				let source = 'webphone-menu'
				if (btn.placement) {
					placement = btn.placement
				} else if (btn.area) {
					placement = btn.area.split('/')[1]
				}
				if (btn.placement || btn.area) source = 'button-builder'
				return { ...btn, placement, source }
			})
			list.sort((a, b) => {
				a.sort = a.sort_order || a.order
				b.sort = b.sort_order || b.order
				return a.sort - b.sort
			})
			return list
		},
		status () {
			if (this.state.session === 'Establishing' || this.state.session === 'Initial') {
				return { text: 'Active', icon: 'mdi-phone-in-talk', color: 'success' }
			} else if (this.state.session === 'Established') {
				return { text: 'Active', icon: 'mdi-phone-in-talk', color: 'white' }
			} else if (this.state.registration === 'Registered') {
				return { text: 'Online', icon: 'mdi-check-circle', color: 'success' }
			}
			return { text: 'Offline', icon: 'mdi-alert-circle-outline', color: 'red' }
		},
		navRight () {
			return this.prov && this.prov.flags && this.prov.flags.includes('MENU_RIGHT')
		},
		title () {
			return (this.prov && this.prov.flags && this.prov.flags.includes('TITLE_USERNAME') && this.prov.sipUser && this.prov.sipUser.username) ? this.prov.sipUser.username : this.prov.brandName
		},
		isAutoAnswer () {
			return this.prov && this.prov.flags && this.prov.flags.includes('AUTO_ANSWER')
		},
		hasDialpad () {
			if (!this.prov || !this.prov.menu) return
			return this.prov.menu.some(item => item.id === 'dialpad')
		},
		sessionState () {
			return this.state.session === 'Initial' || this.state.session === 'Establishing' || this.state.session === 'Established'
		},
		isAnswer () {
			return !this.hasDialpad && (this.state.session === 'Initial' && this.state.direction === 'In')
		},
		isEnd () {
			return !this.hasDialpad && this.sessionState
		},
		// isSessionEstablished () {
			// return !this.hasDialpad && this.state.session === 'Established'
		// },
		isStandalone () {
			return this.prov && !this.prov.setup && this.prov.flags && this.prov.flags.includes('STANDALONE')
		},
        // Implement lazy loading to render only iframe pages that have been opened (hasOpen:true)
		hasOpenIframesArr () {
			if (!this.prov || !this.prov.setup) return []
			return this.iframeComponentsArr.filter(item => item.hasOpen)
		}
	},
	watch: {
		prov: {
			async handler (newVal) {
				if (newVal && newVal.setup && !this.isLoggedIn) {
					this.isLoggedIn = true
					/* Load Buttons: after the user has logged in. */
					try {
						await this.getButton()
					} catch (err) {
						this.throwErr(err)
					}
				}
			},
			deep: true
		},
		$route () {
			// Determine whether the current routing is an iframe page
			this.isOpenIframePage()
		}
	},
	async created () {
		// iframe starts
        // Setting the array object for the iframe page
		const iframeComponentsArr = this.getIframeComponentsArr()
		iframeComponentsArr.forEach((item) => {
			Vue.component(item.name, item.component)
		})
		this.iframeComponentsArr = iframeComponentsArr
		// Determine whether the current routing is an iframe page
		this.isOpenIframePage()
		// iframe ends

		// At time of implement this is not supported by firefox
		// try {
			// const permissionStatus = await navigator.permissions.query({ name: 'microphone' })
			// this.hasMicAccess = permissionStatus.state // granted, denied, prompt
// //			const t = this
// //			permissionStatus.onchange = function () {
// //				this.state = (t.hasMicAccess !== 'denied')
// //			}
		// } catch (e) {
			// // Probably Firefox? We dont know the status of mic access
		// }

		this.$bus.$on('err', errMsg => {
			this.showErr = true
			this.errMsg = errMsg
		})
		window.addEventListener('message', this.postEvent, false)

		// You can't set custom message anymore in most modern browsers
		window.onbeforeunload = function () {
			return 'xyz'
		}

		this.srcTimer = setInterval(this.checkSrcUpdate, 600 * 1000) // Check index.html script src
		this.checkSrcUpdate()
		this.$store.commit('getUuid')

		// Paypal Payment Status
		if (this.$route.query['payment-result']) {
			this.showErr = true
			this.errMsg = 'Payment ' + this.$route.query['payment-result']
		}
//		this.$bus.$on('register', this.register)

		this.$bus.$on('invite', (number) => {
			this.$refs.sipClient.invite(number)
		})
		// this.$bus.$on('hold', () => this.$refs.sipClient.hold())

		const axiosDecrement = error => {
			this.axiosCounter--
			return Promise.reject(error)
		}

		axios.interceptors.request.use(config => {
			this.axiosCounter++
			return config
		}, axiosDecrement)

		axios.interceptors.response.use(response => {
			this.axiosCounter--
			return response
		}, axiosDecrement)
	},
	async mounted () {
		// Do we have a Web Service Available?
		// Yes - No nothing, when the web service registered, it will fire a registration request.
		// No - Do the following
		// if (this.$store.state.isOnline) this.$refs.sipClient.register()

		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault() // Prevent Chrome 67 and earlier from automatically showing the prompt
			this.pwaInstallPrompt = e // Stash the event so it can be triggered later.
		})
		try {
			await this.getProv()
			axios.defaults.headers.common['X-UUID'] = this.uuid
			this.$sentry.setTag('uuid', this.uuid)
			if (this.prov.menu[0] && this.$route.path !== this.prov.menu[0].url) this.$router.push(this.prov.menu[0].url)
			if (this.prov && this.prov.sipUser && this.prov.sipUser.wsServer) {
				this.register()
			}
		} catch (err) {
			this.throwErr(err)
		}
		// this.register()
	},
	beforeDestroy () {
		this.$refs.sipClient.unregister()
		window.removeEventListener('message', this.postEvent, false)
	},
	methods: {
		...mapActions([
			'getProv',
			'getButton'
		]),
		...mapMutations([
			'getUuid', 'incrementAxiosCounter'
		]),
		// getBtnPlacement (btn) {
			// if (btn.source === 'button-builder'btn.placement) {
				// return btn.placement
			// } else if (btn.area) {
				// return btn.area.split('/')[1]
			// } else {
				// return 'footer_nav '
			// }
		// },
		// Setting hasOpen based on current routing
		isOpenIframePage () {
			const target = this.iframeComponentsArr.find(item => {
				return item.path === this.$route.path
			})
			if (target && !target.hasOpen) {
				target.hasOpen = true
			}
		},
		// Traverse all pages of the route to collect iframeComponent identifiers
		getIframeComponentsArr () {
			const router = this.$router
			const routes = router.options.routes
			const iframesArr = routes.filter(item => item.iframeComponent)

			return iframesArr.map((item) => {
				const name = item.name || item.path.replace('/', '')
				return {
					name,
					path: this.$route.path,
					hasOpen: false, // Whether it has been opened, default false
					component: item.iframeComponent // Reference to Component Files
				}
			})
		},

		async checkSrcUpdate () {
			try {
				const { data } = await axios.get('/index.html')
				this.updateAvailable = Array.prototype.slice.call(window.document.getElementsByTagName('script'))
					.map(script => script.src.substr(script.src.indexOf('/', 8)))
					.filter(src => !!/app.[^.]+.js/.exec(src))
					.some(src => data && !data.includes(src))
			} catch (err) {
				// Empty Err
			}
		},
		reloadApp () {
			window.location.reload()
		},
		async pwaInstall () {
			this.pwaInstallPrompt.prompt() // Show the prompt
			// Wait for the user to respond to the prompt
			try {
				await this.pwaInstallPrompt.userChoice
				this.pwaInstallPrompt = null
			} catch (err) {
				// continue regardless of error
			}
		},
		// async pushNotification () {
			// // console.log('this.$store.state.pushRegisteration: ', this.$store.state.pushRegistration)
			// await axios.post('https://cp.ramu.connexcs.com/api/cp/web-push/sendNotification', { subscription: this.$store.state.pushRegistration })
		// },
		reportProblem () {
			throw new Error('User Reported Error')
		},
		async unProvision () {
			try {
				await this.$refs.sipClient.unregister()
				await axios.post('/api/softphone/unprov', { uuid: this.uuid })
				localStorage.clear()
				this.getUuid()
				this.getProv()
				location.reload()
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.unProvDialog = false
			}
		},
		playBeep (duration = 2.5, frequency = 440) {
			const context = new AudioContext()
			const osc = context.createOscillator()
			const gain = context.createGain()
			osc.connect(gain)
			osc.type = 'sine'
			osc.frequency.value = frequency
			gain.connect(context.destination)
			osc.start(0)
			gain.gain.exponentialRampToValueAtTime(
				0.00001, context.currentTime + duration
			)
		},
		onRinging (data) {
			this.state.ringingData = data
			if (this.isAutoAnswer) {
				console.log('Ringing: Auto Answer Enabled')
				this.playBeep()
				setTimeout(() => {
					if (this.state.session === 'Initial') {
						console.log('Auto Answering')
						this.$refs.sipClient.answer()
					}
				}, 1000)
			}
			if (!this.hasDialpad) return
			this.$nextTick(() => {
				this.$router.push('dialpad')
			})
		},
		/**
		 * Toogles the register status if toggling isn't already happening.
		 * @return {undefined}
		 */
		async toggleRegister () {
			if (!this.$refs.sipClient || this.togglingRegistration) return
			this.togglingRegistration = true
			if (this.state.registration === 'Registered') {
				await this.$refs.sipClient.unregister()
				this.userOffline = true
			} else {
				try {
					await this.register()
					this.userOffline = false
				} catch (err) {
					this.throwErr(err)
				}
			}
			this.togglingRegistration = false
		},
		topup () {
			this.$refs.topupDialog.open()
		},
		register (data) {
			const config = data || JSON.parse(localStorage.getItem('SIPConfig'))
			if (config) this.config = config
			return new Promise((resolve, reject) => {
				this.$nextTick(async () => {
					try {
						resolve(await this.$refs.sipClient.register())
					} catch (err) {
						reject(err)
					}
				})
			})
		},
		onStatusChanged (state) {
			const oldState = JSON.parse(JSON.stringify(this.state))
			this.state = state

			if (state && state.direction === 'In' && state.session === 'Establishing') {
				setTimeout(() => {
					if (this.state.session === 'Establishing') {
						this.domInteractivityNeeded = true
					}
				}, 2000)
			}
			if (state && state.direction === 'In' && state.session === 'Established') {
				this.domInteractivityNeeded = false
			}

			if (oldState.register !== state.register) this.emitConnectedWindowEvent('registration-' + state.register.toLowerCase())
			if (oldState.session !== state.session) this.emitConnectedWindowEvent('session-' + state.session.toLowerCase())
			if (oldState.isHeld !== state.isHeld) {
				this.showErr = true
				this.errMsg = state.isHeld ? 'Call on hold' : 'Call is resumed'
				// this.snackbarMsg = state.isHeld ? 'Call On Hold' : 'Call is resumed'
				// this.snackbarVisible = true
			}
		},
		emitConnectedWindowEvent (event, data = {}) {
			const connectedWindow = { source: window.parent, origin: '*', containerId: '*' }
			connectedWindow.source.postMessage({ containerId: connectedWindow.containerId, event, data }, connectedWindow.origin) // payload.origin
		},
		onCdr (record) {
			if (record.cause !== 'Unknown') this.$bus.$emit('err', record.cause)
			this.$store.commit('addCdr', record)
			this.emitConnectedWindowEvent('cdr', record)
		},
		async postEvent (payload) {
			if (!payload || !payload.data || !payload.data.fn || payload.data._type !== 'webphone-sdk') return
			window.lastPayload = payload
			const { id, containerId, data, fn } = payload.data
			let response = {}
			try {
				switch (fn) {
				case 'init':
//					this.connectedWindow = { source: payload.source, origin: payload.origin, containerId }
					break
				case 'config':
					break
				case 'provision':
					break
				case 'call':
					await this.$refs.sipClient.call(data.destination)
					response.status = 'In Progress'
					break
				case 'answer':
					this.$refs.sipClient.answer()
					response.status = 'Success'
					break
				case 'reject':
				case 'hangup':
					this.$refs.sipClient.callEnd()
					response.status = 'Success'
					break
				case 'mute':
					this.$refs.sipClient.mic(false)
					response.status = 'Success'
					break
				case 'unmute':
					this.$refs.sipClient.mic(true)
					response.status = 'Success'
					break
				case 'sendDTMF':
					response = await this.$refs.sipClient.sendDTMF(data.tone)
					break
				case 'register': {
					await this.register(data && Object.keys(data).length ? data : false) // Checks Standalone
					response.status = 'Success'
					break
				}
				case 'unregister': {
					const res = await this.$refs.sipClient.unregister()
					if (!res) throw new Error('Unregister Failed')
					response.status = 'Success'
					break
				}
				}
				payload.source.postMessage({ id, containerId, data: response }, payload.origin) // payload.origin
			} catch (error) {
				payload.source.postMessage({ id, containerId, error: error.message }, payload.origin) // payload.origin
				throw error
			}
			window.postMessage({ id, containerId, response }, payload.src)
		},
		answerCall () {
			this.$refs.sipClient.answer()
		},
		endCall () {
			this.$refs.sipClient.callEnd()
		},
		toggleMic () {
			// this.$refs.sipClient.mic(this.state.isMute)
			if (this.state.isMute) this.$refs.sipClient.mic(this.state.isMute)
			if (this.state.isHeld) this.$refs.sipClient.hold(!this.state.isHeld)
		}
	}
}
</script>
