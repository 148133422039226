<template>
	<v-dialog v-model="show" persistent max-width="600px">
		<dynamic-form :formData="form" :fill-data="fillData" @submit="submit" @change="change" @close="close"></dynamic-form>
	</v-dialog>
</template>
<script>
import DynamicForm from './Dynamic-Form.vue'
// import axios from 'axios'

export default {
	props: {
		fillData: {
			type: Object,
			required: true,
			// default: () => ({}),
			description: 'The data to use to populate/fill the form before rendering.'
		},
		form: {
			type: Object,
			required: true,
			description: 'The form data'
		}
	},
	data () {
		return {
			show: false,
			localFillData: this.fillData
		}
	},
	components: { DynamicForm },
	methods: {
		submit (formData) {
			this.$emit('submit', formData)
		},
		change (formData) {
			this.$emit('change', formData)
		},
		close () {
			this.show = false
			this.$emit('close')
		}
	}
}
</script>
