<script>
import axios from 'axios'
import DynamicButton from './Dynamic-Button.vue'
import DynamicFormDialog from './Dynamic-Form-Dialog.vue'
import RenderContentDialog from './Render-Content-Dialog.vue'

export default {
	props: {
		placement: {
			type: String,
			default: '',
			description: 'The name of the page / placement the buttons are filtered by, e.g. "agent"'
		}
	},
	components: {
		RenderContentDialog,
		DynamicFormDialog,
		DynamicButton
	},
	data () {
		return {
			activeBtn: {},
			dynamicFormObj: {},
			buttonResultObj: {},
			activeForm: {},
			formResultObj: {},
			showFormDialog: false,
			displayResult: false,
			localPlacement: this.placement ? this.placement : this.$route.path.replace('/', '')
		}
	},
	computed: {
		state () {
			return this.globalState ? this.globalState : {}
		}
	},
	methods: {
		openLoadedForm (formObj, formUpdateSchema = [], formUpdateDataObj = {}) {
			/* Merge the two arrays and update where necessary */
			if (!formObj.schema && formObj.schema.length) this.throwErr('Form not found. Check the form name.')
			if (formUpdateSchema.length) {
				formUpdateSchema.forEach(updateField => {
					let isPresent = false // If data is already present in the formObj
					formObj.schema.forEach((localField, localIndex) => {
						if (updateField.name === localField.name) {
							/* Update formObj schema fields from scriptforge's formUpdateSchema fields if their field names match. */
							formObj.schema[localIndex] = { ...localField, ...updateField }
							if (updateField.value) this.dynamicFormObj[updateField.name] = updateField.value
							isPresent = true
						}
					})
					if (!isPresent) {
						formObj.schema.push(updateField)
						if (updateField.value) this.dynamicFormObj[updateField.name] = updateField.value
					}
					this.dynamicFormObj[updateField.name] = updateField.defaultValue && updateField.defaultValue
				})
			}
			/* If a form field matches an existing form field, then update that field's value in the visible form */
			if (formUpdateDataObj && Object.keys(formUpdateDataObj).length) {
				const formFields = formObj.schema.map(field => field.name)
				for (const updateField in formUpdateDataObj) {
					if (formFields.includes(updateField)) {
						this.dynamicFormObj[updateField] = formUpdateDataObj[updateField]
					}
				}
			}
			formObj.schema.map((row, idx) => {
				if (row && !row.name) row.name = idx
				return row
			})
			this.activeForm = formObj
			this.$refs.dynamicFormDialog.show = true
			this.showFormDialog = true
		},
		async openForm (formInfo, formUpdateSchema = [], formUpdateDataObj = {}) {
			/* clear any pre filling data present before opening a new one */
			// this.dynamicFormObj = {}
			try {
				let formObj = {}
				if (isNaN(Number(formInfo.params[0])) && typeof formInfo.params[0] === 'string') {
					/* If the form is referenced by it's name */
					const { data } = await axios.get('/api/softphone/form/')
					formObj = data.filter(form => { return form.name.toLowerCase() === formInfo.params[0].toLowerCase() })[0]
				} else if (!isNaN(Number(formInfo.params[0]))) {
					/* If the form is referenced by it's id */
					const { data } = await axios.get(`/api/softphone/form/${formInfo.params[0]}`)
					formObj = data
				}
				this.openLoadedForm(formObj, formUpdateSchema, formUpdateDataObj)
			} catch (err) {
				this.throwErr(err)
			}
		},
		async dynamicButtonClick (btn) {
			try {
				if (!btn) return
				this.activeBtn = btn
				this.updateButtonColor(btn.type)
				this.runDynamicAction(btn, 'button')
			} catch (err) {
				this.throwErr(err)
			}
		},
		updateButtonColor (color) {
			this.$emit('color-change', this.buttonTypeColors[color])
		},
		closeFormDialog () {
			this.$refs.dynamicFormDialog.show = false
			this.buttonResultObj = {}
			this.dynamicFormObj = {}
		},
		async submitForm ({ activeForm, formData, scriptForgeId }, callback = this.runDynamicAction) {
			if (!activeForm.validate()) {
				this.throwErr('Either required fields are not filled in or validation is failed.')
				return
			}
			try {
				this.pending = true
				const { data } = await axios.post(`/api/softphone/scriptforge/${scriptForgeId}`, formData)
				if (callback && typeof callback === 'function') {
					const proceed = callback(null, 'form', data.__) // 2nd parameter is the caller i.e either 'form' or 'button'
					if (!proceed) {
						this.pending = false
						return
					}
				}
				this.closeFormDialog()
				this.formResultObj = data
				if (!data?.__?.action) this.$refs.renderContentDialog.show = true
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.pending = false
			}
		},
		/*
		 * Run a script-forge script related to a specific button
		 * @params {any} btn - The button whose script to run
		 */
		async runButtonScriptForge (btn) {
			if (!btn) return
			try {
				this.pending = true
				const campaignObj = {}
				if (this.campaign && this.campaign.id) campaignObj.campaign = this.campaign
				if (this.campaignRecord) campaignObj.campaignRecord = this.campaignRecord
				const { data } = await axios.post(`/api/softphone/scriptforge/${btn.sandbox_id}`, campaignObj)
				return data
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.pending = false
			}
		},
		async runDynamicAction (btn, caller, actions = {}) {
			// if a form called this function, close all open forms
			if (!caller || typeof caller !== 'string') return true
			caller = caller.toLowerCase()
			let parsedFn = {}

			/*
			 * Run Button Script Forge
			 * @param {Boolean} populateForm - If the intent is to populate a form, then don't display the ScriptForge results
			 * @return {Boolean}
			 */
			const runScriptForge = async (populateForm = false) => {
				/* Run Button Script Forge */
				this.buttonResultObj = await this.runButtonScriptForge(btn)
				if (!populateForm && !Object.keys(actions).length) this.$refs.renderContentDialog.show = true
				return true
			}

			if (caller === 'button') {
				btn.fn = btn.fn || 'main' // button intial value

				if (btn.form_id) {
					/* If a form btn's 'form' attribute was used to open a form instead of it's fn attribute, then assign the fn attribute correctly. */
					btn.fn = `openForm('${btn.form_id}')`
				} else if (btn.page_id) {
					/* If a page_id is present, then set the btn function as openPage accordingly. */
					btn.fn = `openPage('${btn.page_id}')`
				}
				parsedFn = this.parseFn(btn.fn)

				if (parsedFn?.name?.trim()?.toLowerCase() === 'openform' && btn.sandbox_id) {
					/* If a form is to be opened and scriptforge is also set, then the script forge response should be used to populate the form */
					await runScriptForge(true).then(val => {
						actions = this.buttonResultObj?.__
					})
				} else if (btn.sandbox_id) {
					/* Display the results */
					return await runScriptForge(false)
				}
			} else if (caller === 'form') {
				this.closeFormDialog()
				if (!actions || !actions.action) return true
				parsedFn = actions.action
			}
			const formSchema = actions?.formSchema ? actions.formSchema : []
			const formDataObj = actions?.formData
			let continueOtherAction = false
			/* Run Local Actions */
			switch (parsedFn.name.toLowerCase()) {
			case 'openform':
				this.openForm(parsedFn, formSchema, formDataObj)
				continueOtherAction = false
				this.closeFormDialog()
				break
			case 'openpage':
				/* Open Page Logic */
				this.openPage(parsedFn.params[0])
				continueOtherAction = false
				break
			case 'endcall':
				if (this.sessionState) {
					this.endCall()
				} else {
					this.throwErr('There is no active call.')
				}
				continueOtherAction = false
				break
			case 'togglemic':
				if (this.state.session === 'Established') {
					this.toggleMic()
				} else {
					this.throwErr('A call has to be on going to toggle the mic.')
				}
				continueOtherAction = false
				break
			case 'answercall':
				if (this.state.session === 'Initial' && this.state.direction === 'In') {
					this.answerCall()
				} else {
					this.throwErr('There is no incoming call to answer.')
				}
				continueOtherAction = false
				break
			case 'makecall':
				if (parsedFn.params.length && this.campaignRecord[parsedFn.params[0]]) {
					this.makeCall(this.campaignRecord[parsedFn.params[0]])
				} else {
					this.throwErr(`There is no ${parsedFn.params[0]} campaign record.`)
				}
				continueOtherAction = false
				break
			case 'save':
				if (!this.hasConnected) this.throwErr('Save is disabled.') // do nothing if button is disabled
				if (Object.keys(this.campaignRecord).length && parsedFn.params.length) {
					if (parsedFn.params[0].toLowerCase() === 'yes') {
						this.saveCampaign('Yes')
					} else if (parsedFn.params[0].toLowerCase() === 'no') {
						this.saveCampaign('No')
					} else if (parsedFn.params[0].toLowerCase() === 'unknown') {
						this.saveCampaign('Unknown')
					}
				} else {
					this.throwErr('There is no campaign to save.')
				}
				continueOtherAction = false
				break
			case 'next':
				this.next()
				continueOtherAction = false
				break
			/* Takes two parameters i.e 1: url, 2: target as an array e.g ['www.example.com', '_blank'] */
			case 'redirect':
				this.redirect(parsedFn.params)
				continueOtherAction = false
				break
			default:
				this.throwErr('This action is not supported.')
			}
			return continueOtherAction
		}
	}
}
</script>
