<template>
  <table class="fm-report-table__table" :style="{
    'border-top-width': element.options.borderWidth + 'px',
    'border-top-color': element.options.borderColor,
    'border-left-width': element.options.borderWidth + 'px',
    'border-left-color': element.options.borderColor,
    'width': element.options.width || '100%'
  }"
    v-if="isSubform ? display[element.model] && !subHideFields[rowIndex][element.model] : display[element.model]"
    :class="{
      [element.options && element.options.customClass]: element.options && element.options.customClass ? true : false
    }"
  >
    <tr :key="rIndex" v-for="(row, rIndex) in element.rows">
      <td :style="{
            'border-right-width': element.options.borderWidth + 'px',
            'border-right-color': element.options.borderColor,
            'border-bottom-width': element.options.borderWidth + 'px',
            'border-bottom-color': element.options.borderColor,
            'width': column.options.width,
            'height': column.options.height
          }"
          v-for="(column, i) in row.columns" :key="rIndex + '-' + i"
          :colspan="column.options.colspan"
          :rowspan="column.options.rowspan"
          class="fm-report-table__td"
          valign="top"
          :class="{
            [column.options.customClass]: column.options.customClass?true: false
          }"
        >
		<span v-if="!column.options.invisible">
			<template v-for="columnElement in column.list">

        <generate-col-item
        v-if="columnElement.type == 'grid'"
        :key="columnElement.key"
        :model="dataModels"
        :rules="rules"
        :element="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-col-item>

        <generate-report
        v-else-if="columnElement.type == 'report'"
        :key="columnElement.key"
        :model="dataModels"
        :rules="rules"
        :element="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-report>

        <generate-inline
        v-else-if="columnElement.type == 'inline'"
        :key="columnElement.key"
        :model="dataModels"
        :rules="rules"
        :element="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-inline>

        <generate-tab-item
        v-else-if="columnElement.type == 'tabs'"
        :key="columnElement.key"
        :model="dataModels"
        :rules="rules"
        :element="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-tab-item>

        <generate-collapse
        v-else-if="columnElement.type == 'collapse'"
        :key="columnElement.key"
        :model="dataModels"
        :rules="rules"
        :element="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-collapse>

        <generate-form-item
        v-else
        :key="columnElement.key"
        :models="dataModels"
        :rules="rules"
        :widget="columnElement"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
        >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
        </generate-form-item>
			</template>
		</span>
      </td>
    </tr>
  </table>
</template>

<script>
import GenerateInline from './GenerateInline'
import GenerateColItem from './GenerateColItem'
import GenerateTabItem from './GenerateTabItem'
import GenerateCollapse from './GenerateCollapse'

export default {
	name: 'generate-report',
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue'),
		GenerateInline,
		GenerateColItem,
		GenerateTabItem,
		GenerateCollapse
	},
	props: ['element', 'model', 'rules', 'remote', 'blanks', 'display', 'edit', 'remoteOption', 'platform', 'preview', 'containerKey', 'dataSourceValue', 'eventFunction', 'printRead', 'formComponent', 'isSubform', 'rowIndex', 'subName', 'subHideFields', 'subDisabledFields', 'isDialog', 'dialogName'],
	data () {
		return {
			dataModels: this.model
		}
	},
	methods: {
	},
	watch: {
		model: {
			deep: true,
			handler (val) {
				this.dataModels = this.model
			}
		}
	}
}
</script>
