<template>
	<div>
		<audio ref="audioRemote"></audio>
		<audio ref="audioLocal"></audio>
	</div>
</template>
<script>
import '@/assets/css/sip-client.css'
import { UserAgent, RegistererState, SessionState, Inviter, Registerer } from 'sip.js'
import CircularBuffer from 'circular-buffer'
import { mapState, mapActions } from 'vuex'
const SIP_PING_INTERVAL = 60

export default {
	props: {
		username: String,
		password: String,
		realm: String,
		displayName: String,
		wsServer: String,
		cli: String,
		isTurn: Boolean,
		reconnectionAttempts: { type: Number, default: 10 },
		reconnectionDelay: { type: Number, default: 4 },
		turn: Object
	},
	data () {
		const keypad = [...new Array(10)].map((_, idx) => idx).reduce((obj, idx) => {
			return { ...obj, [idx]: new Audio(`audio/wav/${idx}.wav`) }
		}, {})
		return {
			log: new CircularBuffer(50),
			phone: null,
			isRegistering: false,
			audio: {
				ringtone: new Audio('audio/incoming.mp3'),
				ringbacktone: new Audio('audio/outgoing.mp3'),
				'*': new Audio('audio/wav/star.wav'),
				'#': new Audio('audio/wav/hash.wav'),
				...keypad
			},
			reRegisterPending: false,
			registerer: { state: 'Loading' },
			session: { state: 'Idle' },
			direction: { state: null },
			ringing: { data: {} },
			callStartTime: 0,
			// Used to guard against overlapping reconnection attempts
			attemptingReconnection: false,
			// If false, reconnection attempts will be discontinued or otherwise prevented
			shouldBeConnected: true,
			pendingEmitCdr: null,
			sipPingTimer: null,
			logToConsole: false,
			isMute: false,
			isHeld: false,
			callTimer: null,
			callInSeconds: 0,
			currentCallTime: null,
			callStatsTimer: null,
			callStats: [],
			onNetworkStateControllers: { offline: null, online: null },
			networkDisturbed: false,
			dialedNumber: ''
		}
	},
	computed: {
		...mapState({ prov: state => state.prov })
	},
	async created () {
		// window.onbeforeunload = () => 'If you close this window, you will not be able to make or receive calls from your browser.'};
		// window.onunload       = closePhone
		// this.$bus.$on('pushRegistration', (data) => {
			// this.reRegister()
		// })
		try {
			await this.getProv()
		} catch (err) {
			this.throwErr(err)
		}
		this.sipPingTimer = setInterval(this.sendSipPing, SIP_PING_INTERVAL * 1000)
	},
	beforeDestroy () {
		if (this.phone) this.phone.stop()
		clearInterval(this.sipPingTimer)
		delete this.phone
	},
	watch: {
		'registerer.state': function (value) {
			this.emitStatusChange()
		},
		'session.state': function (value) {
			this.emitStatusChange()
		},
		'direction.state': function (value) {
			this.emitStatusChange()
		},
		'ringing.data': function (value) {
			this.emitStatusChange()
		}
	},
	methods: {
		...mapActions(['getProv']),
		async getMediaDevices (type) {
			const niceName = { audioinput: 'Microphone ', videoinput: 'Camera ', audiooutput: 'Speaker ' }
			let itemNumber = 0
			const enumerateDevices = await navigator.mediaDevices.enumerateDevices()
			const devices = enumerateDevices
				.filter(device => device.kind === type)
				.map(device => {
					const label = (niceName[device.kind] || 'Device ') + (++itemNumber)
					return { id: device.deviceId, label: device.label || label, type: device.kind }
				})
			return devices
		},
		setMediaDevice (id, type = 'audiooutput') {
			if (type === 'audiooutput') {
				this.$refs.audioRemote.setSinkId(id)
			}
			if (type === 'audioinput') {
				this.$refs.audioLocal.setSinkId(id)
			}
		},
		attemptReconnection (reconnectionAttempt = 1) {
			// If not intentionally connected, don't reconnect.
			if (!this.shouldBeConnected) return

			// Reconnection attempt already in progress
			if (this.attemptingReconnection) return

			// Reconnection maximum attempts reached
			if (reconnectionAttempt > this.reconnectionAttempts) {
				this.throwErr('Unable to connect after ' + reconnectionAttempt + ' attempts')
				return
			} else {
				// User Feedback Scenerio
				this.throwErr('Disconnected: Attempting to reconnect ' + reconnectionAttempt)
			}

			// We're attempting a reconnection
			this.attemptingReconnection = true

			setTimeout(async () => {
				// If not intentionally connected, don't reconnect.
				if (!this.shouldBeConnected) {
					this.attemptingReconnection = false
					return
				}

				try {
					await this.phone.reconnect()
					this.attemptingReconnection = false
					// await this.register()
				} catch (error) {
					this.attemptingReconnection = false
					this.attemptReconnection(++reconnectionAttempt)
				}
			}, reconnectionAttempt === 1 ? 0 : this.reconnectionDelay * 1000)
		},
		sendSipPing () {
			if (this.registerer.state !== 'Registered') return
			const core = this.phone.userAgentCore

			// From URI
			const uri = UserAgent.makeURI(`sip:${this.username}@${this.realm}`)
			if (!uri) throw new Error('Failed to create from URI.')

			// Create message
			const message = core.makeOutgoingRequestMessage('OPTIONS', uri, uri, uri, {})

			// Send message
			core.request(message, {
				// onAccept: (response) => {
					// console.log('SIP Ping Successful', response)
				// },
				// onReject: (response) => {
					// console.log('SIP Ping Failed', response)
				// }
			})
		},
		emitStatusChange () {
			this.$emit('onStatusChanged', {
				registration: this.registerer.state,
				session: this.session.state,
				direction: this.direction.state,
				ringing: this.ringing.data,
				isMute: this.isMute,
				isHeld: this.isHeld
			})
		},
		async onConnect () {
			try {
				if (this.registerer.state === 'Unregistered') await this.register()
			} catch (e) {
				this.$emit('onErr', e)
			}
		},
		async onDisconnect (error) {
			// On disconnect, cleanup invalid registrations
			try {
				await this.registerer.unregister()
			} catch (e) {
				// Unregister failed
			}
			// Only attempt to reconnect if network/server dropped the connection
			if (error) this.attemptReconnection()
			throw new Error(error)
		},
		onMessage (message) {
			message.accept()
		},
		onNotify (notification) {
			notification.accept()
		},
		onRefer (referral) {
			referral.accept()
		},
		onSubscribe (subscription) {
			subscription.accept()
		},
		async register () {
			if (!this.wsServer) throw new Error('wsServer not set')
			if (this.phone) await this.phone.stop()
			delete this.phone

			// if (!(SIP.WebRTC && typeof SIP.WebRTC.isSupported === 'function' && SIP.WebRTC.isSupported())) throw new Error('WebRTC is not Supported')
			if (!(navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia || (navigator.mediaDevices && navigator.mediaDevices.getUserMedia))) return this.$emit('onErr', 'WebRTC is not Supported')
			const pushRegistration = this.$store.state.pushRegistration
			let extraHeaders = []
			if (Object.keys(pushRegistration).length) {
				extraHeaders = [
					`push-endpoint=${pushRegistration.endpoint}`,
					`push-auth=${pushRegistration.keys.auth}`,
					`push-p256dh=${pushRegistration.keys.p256dh}`
				]
			}
			const cli = this.cli || this.username

			const basicObj = {
				delegate: {
					onConnect: this.onConnect,
					onDisconnect: this.onDisconnect,
					onInvite: this.onInvite,
					onMessage: this.onMessage,
					onNotify: this.onNotify,
					onRefer: this.onRefer,
					onSubscribe: this.onSubscribe
				},
				uri: UserAgent.makeURI(`sip:${cli}@${this.realm}`),
				registerOptions: {
					expires: 600,
					extraContactHeaderParams: extraHeaders
				},
				userAgentString: 'ConnexCS Web App',
				transportOptions: { wsServers: [this.wsServer] }, // , keepAliveInterval: 60
				authorizationPassword: this.password,
				displayName: this.displayName,
				authorizationUsername: this.username,
				contactParams: { transport: 'wss' },
				// registerExpires: 30,
				autoStop: false,
				logBuiltinEnabled: false,
				logConfiguration: true,
//				logLevel: this.logLevel,
				logConnector: (level, category, label, content) => {
					this.$sentry.addBreadcrumb({ category, level, message: content })

					if (this.logToConsole) this.toConsole(level, category, label, content)
					this.log.enq({ level, category, label, content })
				}
			}
			/*
				// Need to work on this for turn
				sessionDescriptionHandlerFactoryOptions: {
					peerConnectionConfiguration: {
//						iceTransportPolicy: 'relay',
						iceServers: [{ urls: 'turn:turn.connexcs.com:3478?transport=tcp', username: 'turn', credential: '4nCjnNbvSfn4GrbS26aFpaMpSjxZAXYY' }]
					}
			} */
			let options = {}
			if (this.turn) {
				options = { ...basicObj, ...{ sessionDescriptionHandlerFactoryOptions: { peerConnectionConfiguration: this.turn } } }
			} else {
				options = basicObj
			}
			// if (process.env.NODE_ENV === 'development') {
				// options.traceSip = false
				// options.logLevel = { logLevel: 5 }
			// } else {
			// }
			this.phone = new UserAgent(options)

			this.registerer = new Registerer(this.phone)
			try {
				await this.phone.start(options)
				return await this.register2()
			} finally {
				this.isRegistering = true
			}
		},
		async register2 () {
			let promiseFulfilled = false
			const resultPromise = new Promise((resolve, reject) => {
				this.registerer.stateChange.addListener(newState => {
					switch (newState) {
					case RegistererState.Registered:
						this.onRegistered()
						if (!promiseFulfilled) resolve()
						promiseFulfilled = true
						break
					case RegistererState.Unregistered:
						this.onUnregistered()
						break
					case RegistererState.Terminated:
						if (!promiseFulfilled) reject(new Error('Registration Terminated'))
						promiseFulfilled = true
						this.onUnregistered()
						break
					}
				})
			})
			// Send REGISTER
			await this.registerer.register()
			return Promise.race([resultPromise])
		},
		// Handle a full de-register - register cycle
		async reRegister () {
			this.reRegisterPending = true
			if (this.registerer.state === 'Registered') {
				this.unregister()
			} else if (!this.isRegistering) {
				try {
					await this.register()
				} catch (e) {
					this.$emit('onErr', e)
				}
			}
		},
		async unregister (options) {
			if (!this.phone) return false
			try {
				return await this.registerer.unregister(options)
			} catch (err) {
				this.throwErr('Cannot unregister while registering')
				return false
			}
		},
		onRegistered () {
			this.isRegistering = false
			if (this.reRegisterPending) this.unregister()
		},
		async onUnregistered () {
			if (this.reRegisterPending) {
				this.reRegisterPending = false
				try {
					await this.register()
				} catch (e) {
					this.$emit('onErr', e)
				}
			}
		},
		// Make a call (Outgoing)
		call (address) {
			return this.invite(address)
		},
		parseSdp (sdp, rules) {
			const descriptionsRegex = /a=rtpmap:(\d+) (.+)/g
			const audioPriorityListRegex = /m=audio \d+ [^ ]+(.*)/g
			let codecOrder = audioPriorityListRegex.exec(sdp)[1].split(' ').filter(v => v)
			const details = []
			let match = []
			while ((match = descriptionsRegex.exec(sdp)) !== null) {
				details.push({ name: match[2], id: match[1] })
			}
			const codecRegex = (id) => `a=(?:rtpmap|fmtp|rtcp-fb):${id}.*\\r\\n`
			const touchedCodecs = []
			for (const rule of rules) {
				if (rule.match === '*') continue
				const regex = new RegExp(rule.match, 'i')
				details.filter(item => regex.exec(item.name)).forEach(codec => {
					switch (rule.action) {
					case 'priority':
						touchedCodecs.push(codec.id)
						codecOrder = [codec.id, ...codecOrder.filter(id => id !== codec.id)]
						break
					case 'delete':
						if (Number(codec.id) !== 111) {
							codecOrder = codecOrder.filter(id => id !== codec.id)
							sdp = sdp.replace(new RegExp(codecRegex(codec.id), 'gi'), '')
						}
						break
					}
				})
			}

			if (rules.some(rule => rule.match === '*')) {
				codecOrder = codecOrder.filter(c => {
					const included = touchedCodecs.includes(c)
					const isOpus = Number(c) === 111
					if (!included && !isOpus) sdp = sdp.replace(new RegExp(codecRegex(c), 'gi'), '')
					return included || isOpus
				})
			}

			sdp = sdp.replace(/(m=audio [0-9]+ [^ ]+).*/, '$1 ' + codecOrder.join(' '))
			return sdp
		},
		// Outgoing call from our Webphone
		async invite (address) {
			if (!this.phone) throw new Error('Phone not Setup')
			if (!this.session) throw new Error('No Session')
			if (this.session.state !== 'Idle') throw new Error(`Session is ${this.session.state}`)

			const uri = UserAgent.makeURI(`sip:${address}@${this.realm}`)
			if (!uri) throw new Error('Failed to create target URI.')
			this.dialedNumber = address
			this.direction.state = 'Out'
			const parseSdp = this.parseSdp
			let session
			try {
				session = new Inviter(this.phone, uri, {
					sessionDescriptionHandlerModifiers: [
						description => {
							const rules = [
								// Rule Examples
								// { match: 'opus.*', action: 'delete' },
								// { match: 'red.*', action: 'priority' },
							]
							for (const action in this.prov.codec) {
								this.prov.codec[action].forEach(codecPattern => {
									if (codecPattern?.trim()) rules.push({ action, match: codecPattern })
								})
							}
							description.sdp = parseSdp(description.sdp, rules)
							return description
						}
					],
					sessionDescriptionHandlerOptions: {
						constraints: { audio: true, video: false }
					}
				})
			} catch (err) {
				this.throwErr(err)
			}

			try {
				this.audio.ringbacktone.loop = true
				await this.audio.ringbacktone.play()
			} catch (err) {
				this.throwErr('Outgoing call, your browser prevented audio from playing.')
			}
			try {
				this.session = session
				session.stateChange.addListener((newState) => {
					switch (newState) {
					case SessionState.Establishing:
						this.onRinging(session)
						break
					case SessionState.Established:
						this.onAnswer(session)
						break
					case SessionState.Terminated:
						this.onTerminated(session)
						break
					}
				})
			} catch (err) {
				this.throwErr(err)
			}
			const inviteOptions = {
				requestDelegate: {
					// onAccept: (response) => {
						// console.log('Positive response = ', response)
					// },
					onReject: (response) => {
						if (this.pendingEmitCdr) {
							this.$emit('onCdr', { ...this.pendingEmitCdr, cause: `${response.message.statusCode} ${response.message.reasonPhrase}` })
							this.pendingEmitCdr = null
						}
					}
					// onTrying: (response) => {
						// console.log('Trying ', response)
					// },
					// onProgress: (response) => {
						// console.log('Progress ', response)
					// }
				}
			}
			try {
				return await session.invite(inviteOptions)
			} catch (err) {
				console.error(err)
			}
			// // Add a new call to State (Vuex)
			// const options = {
				// sessionDescriptionHandlerOptions: {
					// constraints: { audio: true,	video: false }
				// }
			// }
			// // alwaysAcquireMediaFirst: true,
			// // RTCConstraints: {
				// // optional: [ { DtlsSrtpKeyAgreement: true } ]
			// // }

			// var session = this.phone.invite(address, options)
			// this.onNewSession(session)
		},
		// Outbound Ringing
		async onRinging (session) {
			try {
				this.audio.ringbacktone.loop = true
				await this.audio.ringbacktone.play()
			} catch (err) {
				this.throwErr('Incoming call, your browser prevented audio from playing.')
			}
		},
		// Display Timer When Call Is Active
		startCallTimer () {
			const initialTimestamp = Date.now()
			this.callTimer = setInterval(() => {
				this.callInSeconds = Math.floor((Date.now() - initialTimestamp) / 1000)
				const dateObj = new Date(this.callInSeconds * 1000)
				const hours = dateObj.getUTCHours().toString().padStart(2, '0')
				const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0')
				const seconds = dateObj.getSeconds().toString().padStart(2, '0')

				this.currentCallTime = `${hours}:${minutes}:${seconds}`
			}, 1000)
		},
		// Answer Incoming Call (from our Webphone)
		answer () {
			// if (!this.$store.state.active) return false
			const options = {
				sessionDescriptionHandlerOptions: {
					constraints: { audio: true,	video: false }
				}
			}
			if (!this.session || !this.session.accept) throw new Error('Phone not ringing')
			// Need Ringing Check
			if (this.session.state !== 'Initial') throw new Error('Attempting to answer non-ringing call')
			this.session.accept(options)
			// If you need to add any more code which gets call on answer, place it in onAnswer
		},
		/*
		 * Assigns a value to either the value or 0 if it is falsy
		 */
		assignNumber (value) {
			if (typeof value === 'number') {
				return value
			} else {
				return '-'
			}
		},
		assignString (value) {
			if (typeof value === 'string' && value?.trim()) {
				return value
			} else {
				return '-'
			}
		},
		changeInterval (intervalRepetitions, pc) {
			let firstLimit = 300 // 5 min
			if (!intervalRepetitions || !pc) return
			if (intervalRepetitions === firstLimit) {
				clearInterval(this.callStatsTimer)
				this.startCallStatsInterval(pc, 15000, ++firstLimit) // 15 sec
			}
		},
		startCallStatsInterval (pc, delay = 1000, intervalRepetitions = 0) { // delay is in milisec and intervalRepetitions is seconds
			if (typeof pc !== 'object') {
				clearInterval(this.callStatsTimer)
				this.throwErr('Please check your Peer Connection.')
				return
			}
			this.callStatsTimer = setInterval(async () => {
				/* Change the interval for repitition after 5 min, from 1 second to 15 seconds */
				if (intervalRepetitions === 300) this.changeInterval(intervalRepetitions, pc)
				if (intervalRepetitions <= 300) intervalRepetitions++
				const customReport = {
					roundTripTime: 0,
					jitter: 0,
					packetsReceived: 0,
					packetsLost: 0,
					packetsSent: 0,
					bytesSent: 0,
					bytesReceived: 0,
					time: '-',
					timestamp: '-',
					codec: '-'
				}
				const reportTypes = ['remote-inbound-rtp', 'inbound-rtp', 'outbound-rtp', 'codec']
				const reports = []
				pc.getStats(null).then(stats => {
					stats.forEach((report) => {
						if (reportTypes.includes(report.type)) reports.push(report)
					})
					let isRttSet = false
					for (const report of reports) {
						// for (const stat in report) {
							// stats = stat.toLowerCase()
							// if (stats.includes('codec')) console.log('type', report.type, stat, report[stat], report)
						// }
						const time = new Date(report.timestamp)
						switch (report.type) {
						case 'remote-inbound-rtp':
							customReport.roundTripTime = this.assignNumber(report?.roundTripTime)
							isRttSet = true
							break
						case 'inbound-rtp':
							customReport.jitter = this.assignNumber(report.jitter)
							customReport.packetsReceived = this.assignNumber(report.packetsReceived)
							customReport.packetsLost = this.assignNumber(report.packetsLost)
							customReport.bytesReceived = this.assignNumber(report.bytesReceived)
							customReport.time = `${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}`
							customReport.timestamp = this.assignNumber(report.timestamp)
							break
						case 'outbound-rtp':
							customReport.packetsSent = this.assignNumber(report.packetsSent)
							customReport.bytesSent = this.assignNumber(report.bytesSent)
							break
						case 'codec':
							customReport.codec = this.assignString(report.mimeType.split('/')[1])
							break
						}
					}
					if (!isRttSet) customReport.roundTripTime = 0
					this.callStats.push(customReport)
				})
			}, delay)
		},
		// Outbound Answer - Answering from other end - Incorrect
		// Answer - Inbound & Far End
		onAnswer (session) {
			this.callStartTime = Date.now()
			const pc = session.sessionDescriptionHandler.peerConnection
			// this.onNetworkStateControllers.offline = new AbortController()
			// this.onNetworkStateControllers.online = new AbortController()

			// webphone-vn fix
			// window.addEventListener('offline', this.onNetworkStateChange, { signal: this.onNetworkStateControllers.offline.signal })
			// window.addEventListener('online', this.onNetworkStateChange, { signal: this.onNetworkStateControllers.online.signal })

			const remoteStream = new MediaStream()
			pc.getReceivers()
				.filter(r => r.track)
				.forEach(receiver => remoteStream.addTrack(receiver.track))
			try {
				this.startCallStatsInterval(pc)
			} catch (err) {
				this.throwErr(err)
			}
			this.$refs.audioRemote.srcObject = remoteStream
			this.$refs.audioRemote.autoplay = true
			this.audio.ringtone.pause()
			this.audio.ringbacktone.pause()
			this.startCallTimer()
		},
		// By our Webphone - Reject or End the call after answered or while ringings
		callEnd () {
			if (!this.phone) throw new Error('Phone not Setup')
			if (!this.session) throw new Error('No Session')
			if (this.session.state === 'Established') {
				// Call end by our Webphone after answered, then goes to this.onTerminated() and reaches else in it
				this.session.bye()
			} else if (this.session.state === 'Initial' || this.session.state === 'Establishing') {
				if (this.direction.state === 'In') {
					// Reject By Our Webphone When Incoming
					// try {
					this.session.progress().then(() => {
						this.session.reject()
					})
						// Adding this to a try-catch as this function seems to throw an error
					// } catch (err) {
						// console.error('Incoming call rejected by user')
					// }
					// this.session = { state: 'Idle' }
					// this.direction.state = null
				} else {
					// Reject By Our Webphone When Outgoing
					// then goes to this.onTerminated() and reaches if in it, direction 'out' and no duration
					this.session.cancel()
				}
			} else {
				throw new Error(`Can't end call in state: ${this.session.state}`)
			}
		},
/*
		Reaches onTerminated() two ways based on direction
		Reject or Call end after answered
		(Outgoing)
		1st way (by not our Webphone): Gets triggered from this.invite() -> this.onTerminated()
		2nd way (by our Webphone): Gets triggered from this.callEnd(), then from this.invite() -> this.onTerminated()

		(Incoming)
		1st way (by not our Webphone): Gets triggered from this.onInvite() -> session.Terminated case
		2nd way (by our Webphone): Gets triggered from this.callEnd(), then from this.onInvite() -> this.onTerminated()
		3rd way (by rejecting our webphone): Gets triggered when an incoming call was rejected from End Call Button.
 */
		onTerminated (session) {
			if (this.callStatsTimer) clearInterval(this.callStatsTimer)
			if (this.callTimer) {
				clearInterval(this.callTimer)
				this.callTimer = false
			}
			this.currentCallTime = null
			this.callInSeconds = 0

			// this.networkDisturbed = false
			this.callStats = []
			this.ringing.data = {}
			this.audio.ringtone.pause()
			this.audio.ringbacktone.pause()
			if (this.session.sessionDescriptionHandler) {
				// Check if we have a sessionDescriptionHandler available, if we don't, the call was likely rejected before the SDH was instantiated
				this.mic(true) // Disable any Mute of the mic when we end the call.
			}
			const endTime = Date.now()
			const duration = this.callStartTime ? (endTime - this.callStartTime) / 1000 : 0
			const cdr = {
				id: session.id,
				startTime: this.callStartTime,
				endTime,
				direction: this.direction && this.direction.state && this.direction.state.toLowerCase(),
				duration,
				anonymous: false,
				isCanceled: session.isCanceled,
				remoteUser: session.remoteIdentity.uri.user,
				remoteDisplayName: session.remoteIdentity.displayName,
				remoteFriendlyName: session.remoteIdentity.friendlyName,
				localUser: session.localIdentity.uri.user,
				localDisplayName: session.localIdentity.displayName,
				localFriendlyName: session.localIdentity.friendlyName,
				hasAnswer: !!(session.dialog && duration),
				hasOffer: !!(session.dialog && session.dialog.offer),
				cause: 'Unknown' // payload.cause
			}

			if (cdr.direction === 'out' && !duration) {
				// Outgoing Only
				// Reject from any end (our Webphone or the other end)
				// Delay the Emitting of the CDR, so we can include the fail reason.
				this.pendingEmitCdr = cdr
			} else {
				this.$emit('onCdr', cdr)
			}

			this.callStartTime = 0
			this.session = { state: 'Idle' }
			this.direction.state = null
			// if (this.currentCallTime || this.callInSeconds || this.callTimer) {
				// this.callTimer = false
				// this.currentCallTime = null
				// this.callInSeconds = 0
			// }
		},
		// Inbound / Incoming Call
		async onInvite (session) {
			this.direction.state = 'In'
			session.stateChange.addListener((newState) => {
				switch (newState) {
				case SessionState.Establishing:
					this.onRinging(session)
					break
				case SessionState.Established:
					this.onAnswer(session)
					break
				case SessionState.Terminated:
					// End by not our Webphone (rejected or after answered),
					// then this.onTerminated() is called
					// if answered, reaches else of this.onTerminated()
					this.onTerminated(session)
					break
				}
			})
			this.session = session
			this.audio.ringtone.loop = true
			try {
				await this.audio.ringtone.play()
			} catch (err) {
				this.throwErr('Incoming call, your browser prevented audio from playing.')
			}
			this.ringing.data = {
				remoteUser: session.remoteIdentity.uri.user,
				remoteDisplayName: session.remoteIdentity.displayName,
				remoteFriendlyName: session.remoteIdentity.friendlyName
			}
			this.$emit('onRinging', this.ringing.data)
		},
		async sendDTMF (digit) {
			if (this.session.state !== 'Established') return false
			const pc = this.session.sessionDescriptionHandler
			const res = pc.sendDtmf(digit)
			if (res === false) this.throwErr('Unable to send DTMF')
			try {
				if (res) await this.audio[digit].play()
			} catch (err) {
				this.throwErr('Key pressed, browser prevented audio from playing')
			}
			return res
		},
		mic (micStatus = false) {
			this.isMute = !micStatus
			// const pc = this.session && this.session.sessionDescriptionHandler && this.session.sessionDescriptionHandler.peerConnection
			// if (pc && pc.getSenders) {
				// pc.getSenders().forEach(({ track }) => {
					// if (track) track.enabled = micStatus
				// })
			// }
			this.enableSenderTracks(micStatus)
			this.emitStatusChange()
		},
		getPeerConnection () {
			if (!this.session) throw new Error('Session does not exist.')
			if (!this.session.sessionDescriptionHandler) {
				throw new Error('Session\'s session description handler not instance of SessionDescriptionHandler.')
			}
			let pc = null
			try {
				pc = this.session.sessionDescriptionHandler.peerConnection
				if (!pc) throw new Error('Peer connection closed.')
			} catch (err) {
				this.throwErr(err)
			}
			return pc
		},
	/** Helper function to enable/disable media tracks. */
		enableSenderTracks (enable = false) {
			// if (!window.navigator.onLine || this.networkDisturbed) return
			const pc = this.getPeerConnection()
			if (pc && pc.getSenders) {
				pc.getSenders().forEach(({ track }) => {
					if (track) track.enabled = enable
				})
			}
		},
		/** Helper function to enable/disable media tracks. */
		enableReceiverTracks (enable = false) {
			const pc = this.getPeerConnection()
			if (pc && pc.getReceivers) {
				pc.getReceivers().forEach(({ track }) => {
					if (track) track.enabled = enable
				})
			}
		},
		hold (hold = false) {
			// if (this.session.state !== 'Established') return false
			// if (hold) {
				// console.log('onHold if')
				// this.session.invite({ sessionDescriptionHandlerModifiers: [this.session.sessionDescriptionHandler.holdModifier] })
			// } else {
				// console.log('onHold else')
				// this.session.invite()
			// }
			// New Code Below
			if (!this.session) throw new Error('Session does not exist.')
			const session = this.session

			// Just resolve if we are already in correct state
			if (this.isHeld === hold) return Promise.resolve()

			// const sessionDescriptionHandler = this.session.sessionDescriptionHandler
			if (!this.session.sessionDescriptionHandler) {
				throw new Error('Session\'s session description handler not instance of SessionDescriptionHandler.')
			}
			// this.isHeld = hold

			const self = this
			const options = {
				requestDelegate: {
					onAccept () {
						self.isHeld = hold
						self.emitStatusChange()
						self.enableReceiverTracks(!self.isHeld)
						self.enableSenderTracks(!self.isHeld && !self.isMute)
						if (self.delegate && self.delegate.onCallHold) {
							self.delegate.onCallHold(self.isHeld)
						}
					},
					onReject () {
						// self.logger.warn(`[${self.id}] re-invite request was rejected`)
						self.throwErr(`[${self.id}] re-invite request was rejected`)
						self.enableReceiverTracks(!self.isHeld)
						self.enableSenderTracks(!self.isHeld && !self.isMute)
						if (self.delegate && self.delegate.onCallHold) {
							self.delegate.onCallHold(self.isHeld)
						}
					}
				}
			}

			// Session properties used to pass options to the SessionDescriptionHandler:
			//
			// 1) Session.sessionDescriptionHandlerOptions
			//    SDH options for the initial INVITE transaction.
			//    - Used in all cases when handling the initial INVITE transaction as either UAC or UAS.
			//    - May be set directly at anytime.
			//    - May optionally be set via constructor option.
			//    - May optionally be set via options passed to Inviter.invite() or Invitation.accept().
			//
			// 2) Session.sessionDescriptionHandlerOptionsReInvite
			//    SDH options for re-INVITE transactions.
			//    - Used in all cases when handling a re-INVITE transaction as either UAC or UAS.
			//    - May be set directly at anytime.
			//    - May optionally be set via constructor option.
			//    - May optionally be set via options passed to Session.invite().

			// const sessionDescriptionHandlerOptions = session.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions
			const sessionDescriptionHandlerOptions = session.sessionDescriptionHandlerOptionsReInvite
			sessionDescriptionHandlerOptions.hold = hold
			session.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions

			// Send re-INVITE
			return this.session
				.invite(options)
				.then(() => {
					// preemptively enable/disable tracks
					this.enableReceiverTracks(!hold)
					this.enableSenderTracks(!hold && !this.isMute)
				})
				// .catch((error: Error) => {
				.catch(error => {
					console.error('Caught Error')
					// if (error instanceof RequestPendingError) {
					if (error) {
						// this.logger.error(`[${this.id}] A hold request is already in progress.`)
						this.throwErr('A hold request is already in progress.')
					}
					// throw error
					this.throwErr(error)
				})
		},
/*
		timer (interval, alwaysReject = false) {
			return new Promise((resolve, reject) => {
				setInterval(() => {
					if (alwaysReject) {
						reject(new Error('Timeout'))
					} else {
						resolve()
					}
				}, interval)
			})
		},
		getCircularReplacer () {
			const seen = new WeakSet()
			return (key, value) => {
				if (typeof value === 'object' && value !== null) {
					if (seen.has(value)) return
					seen.add(value)
				}
				return value
			}
		}
 */
		toConsole (levelToLog, category, label, content) {
			const outputFn = console[levelToLog] || console.log
			outputFn(category, label, content)
		},
		debug () {
			this.logToConsole = true
			this.log.toarray().reverse().forEach(({ levelToLog, category, label, content }) => this.toConsole(levelToLog, category, label, content))
		}
	}
}
</script>
