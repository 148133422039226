<script>
import {
	Pagination,
	Dialog,
	Autocomplete,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Input,
	InputNumber,
	Radio,
	RadioGroup,
	RadioButton,
	Checkbox,
	CheckboxButton,
	CheckboxGroup,
	Switch,
	Select,
	Option,
	OptionGroup,
	Button,
	ButtonGroup,
	Table,
	TableColumn,
	DatePicker,
	TimeSelect,
	TimePicker,
	Popover,
	Tooltip,
	Breadcrumb,
	BreadcrumbItem,
	Form,
	FormItem,
	Tabs,
	TabPane,
	Tag,
	Tree,
	Alert,
	Slider,
	Icon,
	Row,
	Col,
	Upload,
	Progress,
	Spinner,
	Badge,
	Card,
	Rate,
	Steps,
	Step,
	Carousel,
	CarouselItem,
	Collapse,
	CollapseItem,
	Cascader,
	ColorPicker,
	Transfer,
	Container,
	Header,
	Aside,
	Main,
	Footer,
	Timeline,
	TimelineItem,
	Link,
	Divider,
	Image,
	Calendar,
	Backtop,
	PageHeader,
	CascaderPanel,
	Loading,
	MessageBox,
	Message,
	Notification,
	Popconfirm
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

export default {
	components: {
		'el-popconfirm': Popconfirm,
		'el-pagination': Pagination,
		'el-dialog': Dialog,
		'el-autocomplete': Autocomplete,
		'el-dropdown': Dropdown,
		'el-dropdownMenu': DropdownMenu,
		'el-dropdownItem': DropdownItem,
		'el-menu': Menu,
		'el-submenu': Submenu,
		'el-menu-item': MenuItem,
		'el-menu-item-group': MenuItemGroup,
		'el-input': Input,
		'el-inputNumber': InputNumber,
		'el-radio': Radio,
		'el-radio-group': RadioGroup,
		'el-radio-button': RadioButton,
		'el-checkbox': Checkbox,
		'el-checkbox-button': CheckboxButton,
		'el-checkbox-group': CheckboxGroup,
		'el-switch': Switch,
		'el-select': Select,
		'el-option': Option,
		'el-option-group': OptionGroup,
		'el-button': Button,
		'el-button-group': ButtonGroup,
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-date-picker': DatePicker,
		'el-time-select': TimeSelect,
		'el-time-picker': TimePicker,
		'el-popover': Popover,
		'el-tooltip': Tooltip,
		'el-breadcrumb': Breadcrumb,
		'el-readcrumb-item': BreadcrumbItem,
		'el-form': Form,
		'el-form-item': FormItem,
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		'el-tag': Tag,
		'el-tree': Tree,
		'el-alert': Alert,
		'el-slider': Slider,
		'el-icon': Icon,
		'el-row': Row,
		'el-col': Col,
		'el-upload': Upload,
		'el-progress': Progress,
		'el-spinner': Spinner,
		'el-badge': Badge,
		'el-card': Card,
		'el-rate': Rate,
		'el-steps': Steps,
		'el-step': Step,
		'el-carousel': Carousel,
		'el-carousel-item': CarouselItem,
		'el-collapse': Collapse,
		'el-collapse-item': CollapseItem,
		'el-cascader': Cascader,
		'el-color-picker': ColorPicker,
		'el-transfer': Transfer,
		'el-container': Container,
		'el-header': Header,
		'el-aside': Aside,
		'el-Main': Main,
		'el-footer': Footer,
		'el-timeline': Timeline,
		'el-timeline-item': TimelineItem,
		'el-link': Link,
		'el-divider': Divider,
		'el-image': Image,
		'el-calendar': Calendar,
		'el-backtop': Backtop,
		'el-page-header': PageHeader,
		'el-cascader-panel': CascaderPanel,
		'el-loading': Loading,
		'el-message-box': MessageBox,
		'el-message': Message,
		'el-notification': Notification
	}
}
</script>
