import axios from 'axios'
import { mapActions } from 'vuex'
export default {
	data: function () {
		return {
			// dynamicFormObj: {},
			// activeForm: {},
			// displayResult: false,
			// formResultObj: {},
			// showFormDialog: false,
			// buttonResultObj: {},
			campaign: {},
			campaignRecord: {},
			hasConnected: false,
			buttonTypeColors: {
				danger: 'red',
				info: 'grey',
				default: 'black',
				primary: 'blue',
				success: 'green',
				warning: 'orange'
			},
			globalError: false
		}
	},
	computed: {
		globalState () {
			if (!this.$root.$children[0].state) return { registration: { state: 'Loading' }, session: { state: 'Loading' }, direction: { state: 'Loading' }, ringing: { data: {} } }
			return this.$root.$children[0].state
		},
		sipClient () {
			return this.$root.$children[0].$refs.sipClient
		}
	},
	methods: {
		...mapActions(['getProv']),
		// duration (value) {
		// 	if (value === undefined || value === null) return '-'
		// 	const parsedValue = parseFloat(value)
		// 	if (!parsedValue) return '0 min'
		// 	if (parsedValue > 0 && parsedValue < 1) return (Math.round(parsedValue * 1000)) + ' ms'
		// 	if (parsedValue < 60) return parsedValue.toFixed(2) + ' sec'
		// 	const min = Math.floor(parsedValue / 60)
		// 	let sec = (parsedValue - (min * 60)) % 60
		// 	sec = sec?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 4})
		// 	return min.toLocaleString() + ' min ' + sec + ' sec'
		// },
		loadPageDialog (id, data = {}) {
			this.$root.$children[0].$refs.page.renderForm(id, data)
		},
		/*
		 * Open A Page Created Using Form Making Editor
		 * @param {String} id - The id of the page to open
		 * @return {Void}
		 */
		openPage (id) {
			this.loadPageDialog(id)
		},
		redirect (params) {
			if (!params.length) this.throwErr('Redirect action needs a url.')
			if (typeof params[0] !== 'string') this.throwErr('Type Error: The URL has to be of type String.')
			if (params[1] && typeof params[1] !== 'boolean') this.throwErr('Type Error: The isExternalLink option has to be of type Boolean.')
			if (params[2] && typeof params[2] !== 'string') this.throwErr('Type Error: The target option has to be of type String.')
			try {
				const protocolCheck = /(:\/\/)/
				const parsedParams = {
					url: params[0], // The url to redirect to
					isExternalLink: protocolCheck.test(params[0]) ? true : params[1] ? params[1] : false, // Whether or not a link leads to an external site
					target: params[2] ? params[2] : '' // Whether or not the link should open in a new tab
				}
				// If the url has a protocol and then mark the link as external
				if (protocolCheck.test(params[0]) && !parsedParams.isExternalLink) parsedParams.isExternalLink = true
				if (!protocolCheck.test(params[0]) && parsedParams.isExternalLink) {
					// If the link is external and it does not have a protocol, default to the https protocol
					parsedParams.url = `https://${params[0]}`
				}
				// If it is NOT an external link, then we assume it is a relative path to the current host
				if (!protocolCheck.test(params[0]) && !parsedParams.isExternalLink) {
					// If the link is NOT external and it does not have a protocol, default to the current host
					parsedParams.url = `${window.location.origin}/#/${parsedParams.url}`
				}

				if (parsedParams.target?.trim() === '_blank') {
					// Open URL in a new tab
					window.open(parsedParams.url)
				} else {
					// Open URL in current tab
					window.location.href = parsedParams.url
				}
			} catch (err) {
				this.throwErr(err)
			}
		},
		async next () {
			try {
				const { data } = await axios.get(`/api/softphone/dialler/campaign/${this.campaign.id}/record`)
				this.campaignRecord = data
				this.hasConnected = false
			} catch (err) {
				this.throwErr(err)
			}
		},
		async saveCampaign (status) {
			try {
				await axios.put(`/api/softphone/dialler/campaign/${this.campaign.id}/record/${this.campaignRecord.key}`, { ...this.campaignRecord, status })
				this.campaignRecord = {}
			} catch (err) {
				this.throwErr(err)
			}
		},
		/**
		 * Make an outgoing call to a specific number.
		 * @param {string} - The phone number to dial.
		 * @returns {object} - A Sip Client Event Manager Instance.
		 */
		async makeCall (dialNumber) {
			/**
			 * If no audio input devices are available, prevent the
			 * call from happening and alert the user
			 */
			const audioInput = await this.sipClient.getMediaDevices('audioinput')
			if (!audioInput.length) {
				this.throwErr('Microphone unavailable.')
				return
			}
			if (this.globalState.session === 'Established') return
			if (!dialNumber) {
				this.globalError = true
				if (this.$store.state.log.length) {
					// const remoteUser = this.$store.state.log[this.$store.state.log.length - 1].remoteUser
					// Using Vue Phone Number Input
					// this.dialNumber = remoteUser.includes('+') ? remoteUser.substr(1) : remoteUser
					// this.dialNumber = remoteUser
					this.globalError = false
				}
				return
			}
			// Using Vue Phone Number Input
			// this.dialNumber = (this.vuePhoneNumberObj.e164.includes('+')) ? this.vuePhoneNumberObj.e164.substr(1) : this.vuePhoneNumberObj.e164
			// this.$bus.$emit('invite', this.dialNumber)
			return await this.getSipClientEventManager('call', dialNumber)
		},
		/**
		 * Calls a method on the global Sip Client Component and attaches it's event manager in its response.
		 * This is to enable easy monitoring of the Sip Client state.
		 * @param {string} fn - The Sip Client function to run.
		 * @param {any} arg - The argument to pass to the function.
		 * @returns {object} a Sip Client Event Manager Instance.
		 */
		async getSipClientEventManager (fn, arg = null, sipClient = this.sipClient) {
			if (!fn || typeof fn !== 'string' || !sipClient[fn]) this.throwErr('Sip action not supported.')
			try {
				const res = await !arg ? sipClient[fn]() : sipClient[fn](arg)
				return {
					data: res,
					$on (event, func) {
						sipClient.$on(event, func)
					},
					$off (event, func) {
						sipClient.$off(event, func)
					}
				}
			} catch (error) {
				this.throwErr(error, true)
			}
		},
		async answerCall () {
			return await this.getSipClientEventManager('answer')
		},
		async endCall () {
			return await this.getSipClientEventManager('callEnd')
		},
		async toggleMic () {
			this.isMicOn = !this.isMicOn
			return await this.getSipClientEventManager('mic', this.isMicOn)
		},
		async toggleHold () {
			return await this.getSipClientEventManager('hold', !this.globalState.isHeld)
		},
		/**
		 * Checks if a string is a function, parses it and deduces it's name and arguments.
		 * @param {String} fn - the function to parse into an object format.
		 * @return {Boolean|Object} false if the fn is not a function or else an object representating a function i.e {name:'fn_name', params:['arg1', 'args2'[, arg..]]}.
		 */
		parseFn (fn) {
			if (!fn) return
			const PARAMS_REGEX = /\(\s*([^)]+?)\s*\)/
			const FN_NAME_REGEX = /([a-zA-Z_{1}][a-zA-Z0-9_]+)(?=\()/g
			const IS_FN = /^([_a-zA-Z0-9]+)\((.*)\)$/gm
			const parsedFn = {}
			if (IS_FN.test(fn)) {
				parsedFn.params = PARAMS_REGEX.exec(fn) ? PARAMS_REGEX.exec(fn)[1].replace(/'/g, '').split(',') : []
				parsedFn.name = fn.match(FN_NAME_REGEX) ? fn.match(FN_NAME_REGEX)[0] : ''
			} else {
				return false
			}
			return parsedFn
		},
		/**
		 * Deduce your data's data type: text, object, html.
		 * Or redirect the page if it is a url.
		 * @param {any} data - The data to work on
		 * @return {string} - The data type
		 */
		dynamicDataType (data) {
			if (!data) return 'text'
			let type = false
			if (typeof data !== 'string') {
				type = 'object'
				if (data._redirectUrl) {
					window.open(data._redirectUrl, '_blank')
				}
			} else if (data.substr(0, 1) === '<') {
				type = 'html'
			} else {
				type = 'text'
			}
			return type
		},
		async runScriptForge (scriptId, func = 'main', payload = {}) {
			if (!scriptId || isNaN(scriptId)) return new Error('The variable "scriptId" should be a string or a number.')
			const { data } = await axios.post(`/api/softphone/scriptforge/${scriptId}?fn=${func}`, payload)
			return data
		},
		throwErr (err, throwError = false) {
			let message = err
			if (err.message) message = err.message
			if (message === 'audit_cancel') return
			if (err.response && err.response.data && err.response.data.message) message = err.response.data.message
			if (err.data && err.data.message) message = err.data.message
			if (err.stack) {
				console.error(err.message, err.stack)
			} else {
				console.error(err)
			}
			this.$bus.$emit('err', message)
			// if (message.includes('\n')) {
				// this.$alert(message.replace('\n', '<br/>'), 'Error', {type: 'error', dangerouslyUseHTMLString: true})
			// } else {
				// this.$message({ message, type: 'error' })
			// }
			if (throwError) throw new Error(err)
		}
	}
}
