<template>
  <div class="fm-inline-container"
    v-if="isSubform ? display[element.model] && !subHideFields[rowIndex][element.model] : display[element.model]"
    :class="{
      [element.options && element.options.customClass]: element.options && element.options.customClass ? true : false
    }"
  >
    <template v-for="item in element.list">

      <generate-form-item
        :key="item.key"
        :models="dataModels"
        :rules="rules"
        :widget="item"
        :remote="remote"
        :blanks="blanks"
        :display="display"
        :sub-hide-fields="subHideFields"
        :sub-disabled-fields="subDisabledFields"
        :edit="edit"
        :remote-option="remoteOption"
        :platform="platform"
        :preview="preview"
        :container-key="containerKey"
        :data-source-value="dataSourceValue"
        :event-function="eventFunction"
        :print-read="printRead"
        :form-component="formComponent"
        :style="{'margin-right': element.options.spaceSize+'px'}"
        :is-subform="isSubform"
        :row-index="rowIndex"
        :sub-name="subName"
        :is-dialog="isDialog"
        :dialog-name="dialogName"
      >
        <template v-slot:[blank.name]="scope" v-for="blank in blanks">
          <slot :name="blank.name" :model="scope.model"></slot>
        </template>
      </generate-form-item>
    </template>
  </div>
</template>

<script>
export default {
	name: 'generate-inline',
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue')
	},
	props: ['element', 'model', 'rules', 'remote', 'blanks', 'display', 'edit', 'remoteOption', 'platform', 'preview', 'containerKey', 'dataSourceValue', 'eventFunction', 'printRead', 'formComponent', 'isSubform', 'rowIndex', 'subName', 'subHideFields', 'subDisabledFields', 'isDialog', 'dialogName'],
	data () {
		return {
			dataModels: this.model
		}
	},
	methods: {
	},
	watch: {
		model: {
			deep: true,
			handler (val) {
				this.dataModels = this.model
			}
		}
	}
}
</script>

<style lang="scss">
.fm-inline-container{
  > *{
    display: inline-block;
    vertical-align: top;
  }
}
</style>
