<template>
	<v-dialog v-if="dialogVisible" v-model="dialog" persistent max-width="500px">
		<v-card>
			<v-container>
				<v-layout wrap>
					<v-card-text>
						<div v-if="html">
							<p v-html="html"></p>
							<v-btn block outlined @click="close" class="mt-2" id="btn_provision_setup_dialog_close">Close</v-btn>
						</div>
						<div v-else>
						<v-tabs v-model="activeTab">
							<v-tab href="#Login">Login</v-tab>
							<v-tab v-if="prov && prov.flags && prov.flags.includes('REGISTER')" href="#Register">Register</v-tab>
							<v-tab v-if="isStandalone" href="#Standalone">Standalone</v-tab>
						</v-tabs>
						<v-tabs-items v-model="activeTab">
							<v-tab-item value="Login">
								<v-form ref="form1" @submit.prevent.native>
									<v-flex xs12>
										<v-text-field label="Username*" v-model="formData.username" :rules="[rules.required]" :hint="usernameType.hint" @change="showVerifyField = false"></v-text-field>
										<!-- :rules="rules.email" ||  :rules="rules.mobile" -->
									</v-flex>
									<v-flex xs12 class="d-flex align-center">
										<v-flex xs12 v-if="!showVerifyField">
											<v-text-field
												label="Password*"
												v-model="formData.password"
												:rules="[rules.required]"
												:append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
												:type="showPass ? 'text' : 'password'"
												@click:append="showPass = !showPass"
											></v-text-field>
										</v-flex>
										<v-flex xs12 v-if="showVerifyField">
											<v-text-field label="OTP*" v-model="formData.code" :rules="[rules.required]" persistent-hint :hint="`OTP sent to ${usernameType.type}`"></v-text-field>
										</v-flex>
										<v-spacer v-if="usernameType.type === 'mobile' || usernameType.type === 'email'"></v-spacer>
										<v-flex v-if="usernameType.type === 'mobile' || usernameType.type === 'email'" xs12 sm1>Or</v-flex>
										<v-flex v-if="usernameType.type === 'mobile' || usernameType.type === 'email'" xs12 sm4>
											<v-btn color="primary" v-if="usernameType.type === 'mobile'" @click="verfiy('mobile')" :disabled="showVerifyField" id="btn_provision_setup_dialog_verify_mobile">Verify Mobile</v-btn>
											<v-btn color="primary" v-if="usernameType.type === 'email'" @click="verfiy('email')" :disabled="showVerifyField" id="btn_provision_setup_dialog_verify_email">Verify Email</v-btn>
										</v-flex>
									</v-flex>
								</v-form>
								<div>
									<v-btn block color="primary" @click="deploy('form1')" :loading="pending" :disabled="pending" id="btn_provision_setup_dialog_deploy">Continue</v-btn>
								</div>
							</v-tab-item>

							<v-tab-item value="Register">
								<v-form ref="registerForm" @submit.prevent.native>
									<v-flex xs12 v-if="emailVerify">
										<v-text-field label="Name*" v-model="registerData.name" :rules="[rules.required]" hint="Name" @change="showVerifyField = false" @keyup.enter.native="submit('registerForm')"></v-text-field>
									</v-flex>
									<v-flex xs12 v-else>
										<v-text-field v-model="registerData.email" :rules="rules.email" label="E-mail" required></v-text-field>
									</v-flex>
								</v-form>
								<div>
									<v-btn block color="primary" @click="submit('registerForm')" :loading="registerPending" :disabled="registerPending" id="btn_provision_setup_dialog_submit">Submit</v-btn>
								</div>
							</v-tab-item>

							<v-tab-item value="Standalone">
								<settings :popup="true"></settings>
							</v-tab-item>
						</v-tabs-items>
						</div>
					</v-card-text>
				</v-layout>
			</v-container>
		</v-card>

		<v-snackbar v-model="messageBox">
			{{message}}
			<v-btn color="pink" text id="btn_provision_setup_dialog_message_box" @click="messageBox = false">Close</v-btn>
		</v-snackbar>
	</v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import axios from 'axios'
import Settings from '@/views/Settings.vue'
export default {
	components: { Settings },
	data () {
		return {
			activeTab: null,
			dialog: true,
			registerPending: false,
			pending: false,
			showPass: false,
			html: '',
			showVerifyField: false,
			messageBox: false,
			message: '',
			emailVerify: false,
			registerData: { name: '', email: '' },
			formData: { username: '', password: '', email: '', mobile: '', code: '', state: 0 },
			rules: {
				required: v => !!v || 'Required',
				email: [
					v => !!v || 'Required',
					v => /.+@.+/.test(v) || 'E-mail must be valid'
				],
				mobile: [
					v => !!v || 'Required',
					v => /^\d{9,13}$/.test(v) || 'Should match E.164 format i.e Min 9 - Max 13 digits'
				]
			}
		}
	},
	computed: {
		...mapState({
			prov: state => state.prov,
			uuid: state => state.uuid
		}),
		usernameType () {
			const mobileMatch = /^\+?([1-9][0-9]{8,12})$/.exec(this.formData.username)
			const leadingZero = (this.formData && this.formData.username && this.formData.username.substr(0, 1) === '0') ? 'Mobile number? Enter international format.' : ''
			if (mobileMatch) {
				return { type: 'mobile', hint: '', value: mobileMatch[1] }
			}
			const emailRegEx = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
			if (emailRegEx.test(this.formData.username)) {
				return { type: 'email', hint: '', value: this.formData.username }
			}
			return { type: 'username', hint: leadingZero, value: this.formData.username }
		},
		isStandalone () {
			return this.prov && !this.prov.setup && this.prov.flags && this.prov.flags.includes('STANDALONE')
		},
		isSipRegistered () {
			if (!this.$root.$children[0].state) return false
			return this.$root.$children[0].state.registration === 'Registered'
		},
		dialogVisible () {
			if (!this.prov) return false
			if (this.isStandalone) return !this.isSipRegistered
			return this.prov.setup === false
		}
	},
	created () {
		if (this.$route.query && this.$route.query['email-verify']) {
			this.activeTab = 'Register'
			this.emailVerify = this.$route.query['email-verify']
		}
	},
	methods: {
		...mapActions([
			'getProv'
		]),
		async verfiy (type) {
			try {
				this.showVerifyField = true
				let tempData = {}
				if (type === 'mobile') tempData = { mobile: this.usernameType.value }
				if (type === 'email') tempData = { email: this.usernameType.value }
				await axios.post(`/api/softphone/verify-${type}`, tempData)
			} catch (err) {
				this.throwErr(err)
			}
		},
		async deploy (formName) {
			try {
				const tempData = {}
				if (this.formData.code) {
					tempData.code = this.formData.code
				} else {
					tempData.password = this.formData.password
				}
				tempData.uuid = this.uuid
				tempData.username = this.usernameType.value

				if (!this.$refs[formName].validate()) return
				this.pending = true
				const { data } = await axios.post('/api/softphone/deploy', tempData)
				this.html = data.html || ''
			} catch (err) {
				if (this.formData.password.endsWith(' ')) {
					err.response.data.message += ', The password may contain an unexpected extra character at the end'
				}
				this.throwErr(err)
			} finally {
				this.pending = false
			}
		},
		async close () {
			try {
				await this.getProv()
				this.dialog = false
				this.$bus.$emit('provision')
				// this.$bus.$emit('register')
				this.$root.$children[0].register()
				this.formData = { username: '', password: '', email: '', mobile: '', code: '', state: 0 }
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.html = ''
			}
		},
		async submit (formName, autoClose = false) {
			try {
				if (!this.$refs[formName].validate()) return
				this.registerPending = true
				this.messageBox = false
				this.message = ''
				if (this.emailVerify) {
					const { data } = await axios.post('/api/softphone/deploy', { name: this.registerData.name, uuid: this.uuid, 'email-verify': this.emailVerify })
					this.html = data.html || ''
					if (autoClose) return await this.close()
					// Email is sent successfully (use vuetify snackbar to show message)
				} else {
					await axios.post('/api/softphone/email-register-link', { email: this.registerData.email })
					this.messageBox = true
					this.message = 'E-mail sent successfully'
					// Email is sent successfully (use vuetify snackbar to show message)
				}
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.registerPending = false
			}
		}
	}
}
</script>
