<template>
	<v-dialog v-model="show" persistent max-width="600px">
		<v-card>
			<v-card-title>
				<span class="text-h5">{{title}}</span>
			</v-card-title>
			<v-card-text>
				<render-content v-show="buttonResultObj" ref="userButtonResult" :dataType="dynamicDataType(buttonResultObj)" :content="buttonResultObj"></render-content>
				<render-content v-show="formResultObj" ref="userFormResult" :dataType="dynamicDataType(formResultObj)" :content="formResultObj"></render-content>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="() => {show = false; localButtonResultObj = {}; localFormResultObj = {};}">
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import RenderContent from '@/components/Render-Content.vue'

export default {
	props: {
		buttonResultObj: {
			type: Object,
			required: true
		},
		formResultObj: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			default: 'Response',
			description: 'The title of the content to render.'
		}
	},
	data () {
		return {
			show: false,
			localButtonResultObj: this.buttonResultObj,
			localFormResultObj: this.formResultObj
		}
	},
	watch: {
		show (newVal) {
			this.$emit('input', newVal)
		}
	},
	components: { RenderContent }
}
</script>
