<script>
import CompactElementUi from './Compact-Element-Ui.vue'
import GenerateInline from '../GenerateInline.vue'

export default {
	extends: GenerateInline,
	components: {
		GenerateFormItem: () => import('./GenerateFormItem.vue')
	},
	mixins: [CompactElementUi]
}
</script>
