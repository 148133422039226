import Vue from 'vue'
import Router from 'vue-router'
import Page from '@/views/Page.vue'
import CompactPage from '@/views/Compact-Page.vue'
import VisualVoicemail from '@/views/Visual-Voicemail.vue'
import Cdr from '@/views/CDR.vue'

/*
To Handle Error [Vue warn]: Error in v-on handler (Promise/async): "NavigationDuplicated"
*/
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
	routes: [
		{ path: '/call-log', name: 'callLog', component: () => import('./views/Call-Logs.vue') },
		{ path: '/dialpad', name: 'dialpad', component: () => import('./views/Dial-Pad.vue') },
		{ path: '/agent', name: 'agent', component: () => import('./views/Agent/View.vue') },
		// { path: '/answer-call', name: 'answerCall', component: () => import('./views/Answer-Call.vue') },
		{ path: '/contacts', name: 'contacts', component: () => import('./views/Contacts.vue') },
		{ path: '/settings', name: 'settings', component: () => import('./views/Settings.vue') },
		{ path: '/page/:id', name: 'page', iframeComponent: Page },
		{ path: '/compact-page/:id', name: 'compact-page', component: CompactPage },
		{ path: '/visual-voicemail', name: 'visual-voicemail', component: VisualVoicemail },
		{ path: '/cdr', name: 'cdr', component: Cdr },
		{
			path: '/',
			beforeEnter: (to, from, next) => {
				if (window.location.href.includes('voip.customerwise')) {
					next('compact-page/38')
				} else if (window.location.href.includes('webphone.cacious.connexcs.com')) {
					next('/compact-page/40')
				} else {
					next()
				}
			}
		}
	]
})
