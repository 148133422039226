<template>
    <v-dialog v-model="showTopupDialog" scrollable persistent width="300px">
		<v-card>
			<v-card-title>Topup</v-card-title>
			<v-card-text style="height: 300px;">
				<v-form ref="form">
					<v-flex xs12>
						<v-select v-model="formData.type" label="Pay Via" :items="paymentMethodsArr" item-text="label" item-value="type" :rules="[rules.required]" @change="changePaymentMethod"></v-select>
					</v-flex>
					<v-flex xs12>
						<v-text-field v-model="formData.code" label="Voucher" @change="voucherCheck"></v-text-field>
					</v-flex>
<!--
					<v-flex xs12>
						<v-text-field v-model="formData.type" label="Pay Via" readonly></v-text-field>
					</v-flex>
					<v-flex xs12>
						<card class='stripe-card' :class='{ complete }' stripe='pk_test_XXXXXXXXXXXXXXXXXXXXXXXX' :options='stripeOptions' @change='complete = $event.complete'/>
						<div v-if="formData.type === 'stripe'" style="margin-bottom: 10px;">
							<div v-loading="stripeCardsPending">
								<h4 style="margin-top: 0;">Want to select from your existing Cards?</h4>
								<el-form-item label="" label-width="35px">
									<el-radio-group v-model="stripeCard" class="el-radio-group-block el-radio-group-block-margin-left-0" size="mini">
										<el-radio v-for="(card, index) in stripeCards" :key="index" :label="card.id">
											<span><i class="fa" :class="`fa-cc-${card.brand.toLowerCase()}`"></i> ******{{card.last4}}</span>
											<span>{{card.exp_month}} / {{card.exp_year.toString().slice(-2)}}</span>
										</el-radio>
										<el-radio :label="`${$t('other')}`">New Card</el-radio>
									</el-radio-group>
								</el-form-item>
							</div>
							<card v-if="stripeCard === 'other'" class='stripe-card' :class='{ complete }' :stripe='auth.payment_methods.stripe.key' :options='stripeOptions' @change='complete = $event.complete'/>
						</div>
					</v-flex>
-->
					<v-flex xs12 v-if="formData.type !== 'voucher'">
						<v-text-field label="Amount" v-model="formData.amount" :rules="[rules.required]"></v-text-field>
					</v-flex>
					<v-flex xs12>
						<v-select v-model="formData.currency" label="Currency" :items="currencies" item-text="label" item-value="value" :rules="[rules.required]"></v-select>
					</v-flex>
				</v-form>
				<v-alert v-if="formData.amount < minPayment" type="warning">
					Your minimum payment is {{minPayment | currency(formData.currency)}}
				</v-alert>
				<v-alert v-else-if="formData.amount != paymentTotal && formData.currency" type="success">
					Expected Topup: {{paymentTotal | currency(formData.currency)}}
				</v-alert>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-btn text @click="clear" class="mt-2" id="btn_topup_dialog_close">Close</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="success" @click="pay" ripple :disabled="formData.amount < minPayment" id="btn_topup_dialog_pay">Pay</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<style scoped>
.stripe-card {
  width: 100%;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
.StripeElement {
  background-color: white;
  height: 20px;
  padding: 10px 12px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid gray;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'

import { createToken } from 'vue-stripe-elements-plus' // Card,
const script = document.createElement('script')
script.src = 'https://checkout.stripe.com/checkout.js'
document.getElementsByTagName('head')[0].appendChild(script)

export default {
	// components: { Card },
	data () {
		return {
			showTopupDialog: false,
			showTopupDialogPending: false,

			complete: false,
			stripeCardsPending: false,
			stripeCards: [],
			stripeOptions: {
				style: {
					// base: 'el-input__inner'
					base: {
						color: '#32325d',
						lineHeight: '18px',
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: 'antialiased',
						fontSize: '16px',
						'::placeholder': {
							color: '#aab7c4'
						}
					},
					invalid: {
						color: '#fa755a',
						iconColor: '#fa755a'
					}
				}
			},

			formData: {
				type: 'paypal',
				amount: 0,
				currency: '',
				code: ''
			},
			rules: {
				required: v => !!v || 'Required'
			},

			voucherData: false,
			voucherPending: false
		}
	},
	computed: {
		...mapState({
			prov: state => state.prov
		}),
		paymentMethods () {
			const obj = {}
			if (this.prov.paymentMethods.paypal) obj.paypal = this.prov.paymentMethods.paypal
			obj.voucher = { label: 'Voucher', currencies: ['USD'], min_payment: 0 }
			return obj
		},
		paymentMethodsArr () {
			if (!this.paymentMethods[this.formData.type]) return
			return Object.entries(this.paymentMethods).map(([type, obj]) => ({ type, ...obj }))
		},
		currencies () {
			if (!this.paymentMethods[this.formData.type]) return
			const list = this.paymentMethods[this.formData.type].currencies.map(item => ({ label: item.toUpperCase(), value: item }))
			return list
		},
		minPayment () {
			if (!this.paymentMethods[this.formData.type]) return 0
			let minVal = 0
			if (this.voucherData && this.voucherData.min_activation) minVal = this.voucherData && this.voucherData.min_activation
			if (!this.formData.type) return minVal
			if (this.paymentMethods[this.formData.type].min_payment > minVal) minVal = this.paymentMethods[this.formData.type].min_payment
			return minVal
		},
		paymentTotal () {
			if (!this.voucherData) return this.formData.amount
			return (this.formData.amount * (this.voucherData.multiplier || 1)) + this.voucherData.addition
		}
	},
	methods: {
		...mapActions([
			'getProv'
		]),
		open () {
			this.showTopupDialog = true
			this.formData.type = 'paypal' // If not set, changePaymentMethod is throwing an error as undefined
			this.changePaymentMethod()
		},
		clear () {
			this.$refs.form.reset()
			this.showTopupDialog = false
		},
		changePaymentMethod () {
			if (this.formData.amount < this.minPayment) this.formData.amount = this.minPayment
			if (!this.paymentMethods[this.formData.type].currencies.includes(this.formData.currency)) {
				this.formData.currency = this.paymentMethods[this.formData.type].currencies[0]
			}
			if (this.formData.type === 'stripe') this.getStripeCard()
			if (this.formData.type === 'voucher') this.formData.amount = 0
		},
		async voucherCheck () {
			try {
				if (!this.formData.code) {
					this.voucherData = false
					return
				}
				this.voucherPending = true
				const { data } = await axios.post('/api/softphone/payment/voucher-check', { code: this.formData.code })
				this.voucherData = data
			} catch (err) {
				this.voucherData = false
				this.throwErr(err)
			} finally {
				this.voucherPending = false
			}
		},
		async getStripeCard () {
			try {
				this.stripeCardsPending = true
				const { data } = await axios.get('/api/softphone/payment/stripe/card')
				this.stripeCards = data
			} catch (err) {
				this.throwErr(err)
			} finally {
				this.stripeCardsPending = false
			}
		},
		async pay () {
			if (!this.$refs.form.validate()) return
			// createToken returns a Promise which resolves in a result object with
			// either a token or an error key.
			// See https://stripe.com/docs/api#tokens for the token object.
			// See https://stripe.com/docs/api#errors for the error object.
			// More general https://stripe.com/docs/stripe.js#stripe-create-token.
			if (this.formData.type === 'stripe') {
				try {
					await Promise.resolve()
					const stripeData = this.stripeCard === 'other' ? createToken() : false
					if ('error' in stripeData) return Promise.reject(stripeData.error)
					this.showTopupDialogPending = true
					const postData = { amount: this.formData.amount, currency: this.formData.currency }
					if (stripeData) postData.token = stripeData.token.id
					await axios.post('/api/softphone/payment/stripe/charge', postData)
					this.throwErr('Payment is successful')
					// this.getProv()
					// this.getAuthInfo()
					// this.$refs.formData.reset()
					this.$refs.form.reset()
					this.showTopupDialog = false
				} catch (err) {
					this.throwErr(err)
				} finally {
					this.dialogPending = false
				}
			} else {
				this[this.formData.type]()
				this.showTopupDialog = false
			}
		},
		async paypal () {
			try {
				const paypalData = {
					desc: 'VOIP Minutes',
					return_url: '/#/payment',
					cancel_url: '/#/payment',
					currency: this.formData.currency,
					amount: this.formData.amount
				}
				const { data } = await axios.post('/api/softphone/payment/paypal', paypalData)
				window.open(data.redirect, '_self')
			} catch (err) {
				this.throwErr(err)
			}
		},
		stripe () {
			this.stripeHandler.open({
				name: this.auth.brandName,
				currency: this.auth.currency,
				description: 'Balance Topup',
				amount: this.formData.amount * 100,
				email: this.auth.email,
				billingAddress: true,
				zipCode: true
			})
		},
		voucher () {
			this.bank()
		},
		async bank () {
			try {
				await axios.post('/api/softphone/payment', this.formData)
				this.throwErr('Payment is successful')
				this.getProv()
			} catch (err) {
				this.throwErr(err)
			}
		},
		setupStripe () {
			if (!this.auth.payment_methods.stripe) return {}
			this.stripeHandler = window.StripeCheckout.configure({
				key: this.auth.payment_methods.stripe.key,
				token: async function (token) {
					try {
						const payload = {
							amount: this.formData.amount * 100,
							token
						}
						await axios.post('/api/softphone/payment/stripe-charge', payload)
						this.throwErr('Payment Successfully Completed')
						this.getProv()
					} catch (err) {
						this.throwErr(err)
					}
				}
			})
		}
	}
}
</script>
