import moment from 'moment'
//  For dropdown range Eg: 1 - 30
// Implementation Eg:
// Way 1 :
// 	<select class="form-control" name="noc" ng-model="profileData.noc" ng-options="n for n in [] | range:0:20"></select>
// Way 2 :
// 	<select class="form-control" name="noc" ng-model="profileData.noc" >
// 		<option ng-repeat="n in [] | range:20:30" value="{{n}}">{{n}}</option>
// 	</select>
// let s = {
	// range (input, min, max, type) {
		// min = parseInt(min)
		// max = parseInt(max)

		// for (var i = min; i <= max; i++) {
			// input.push(i)
		// }
		// if (type === 'string') return input.map(String)
		// return input
	// }
// }
const s = {}
// s.range = function (input, min, max, type) {
	// min = parseInt(min)
	// max = parseInt(max)

	// for (var i = min; i <= max; i++) {
		// input.push(i)
	// }
	// if (type === 'string') return input.map(String)
	// return input
// }
// s.limitTextLength = function (input, limit) {
	// return input.length < limit ? input : (input.slice(0, limit) + '...')
// }
// s.bytes = function (input) {
	// var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	// if (input === 0) return '0 Byte'
	// var i = parseInt(Math.floor(Math.log(input) / Math.log(1024)))
	// return Math.round(input / Math.pow(1024, i), 2) + ' ' + sizes[i]
// }
s.capitalize = function (input) {
	return (input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : ''
}
s.decimal = function (value, decimalDigits) {
	decimalDigits = decimalDigits || 0
	return value ? value.toLocaleString(undefined, { minimumFractionDigits: decimalDigits, maximumFractionDigits: decimalDigits }) : 0
}
s.date = function (value, format) {
	const tz = ('; ' + document.cookie).split('; timezone=').pop().split(';').shift()
	let date = null
	if (!value) return '-'
	if (format === 'fromNow') {
		return moment.parseZone(value).fromNow()
	}
	if (moment.isMoment(value)) { // Moment
		date = value
	} else if (!isNaN(value) && value < 10000000000) { // Unix Timestamp
		date = moment.unix(value)
	} else if (moment.isDate(value) || isNaN(value)) { // Javascript Date Object
		date = moment(value)
	} else {
		return 'Unknown Date Format'
	}
	if (!format) format = 'lll'
	if (tz) date = date.tz(tz)
// /*
	// Time												LT	8:30 PM
	// Time with seconds									LTS	8:30:25 PM
	// Month numeral, day of month, year					L	09/04/1986
														// l	9/4/1986
	// Month name, day of month, year						LL	September 4, 1986
														// ll	Sep 4, 1986
	// Month name, day of month, year, time				LLL	September 4, 1986 8:30 PM
														// lll	Sep 4, 1986 8:30 PM
	// Month name, day of month, day of week, year, time	LLLL	Thursday, September 4, 1986 8:30 PM
														// llll	Thu, Sep 4, 1986 8:30 PM
// */
	return date.format(format)
}
s.duration = function (value) {
	if (value === undefined || value === null) return '-'
	const parsedValue = parseFloat(value)
	if (parsedValue > 0 && parsedValue < 1) return (Math.round(value * 1000)) + ' ms'
	if (parsedValue < 60) return value.toFixed(2) + ' sec'
	return (parsedValue) ? Math.round(parsedValue / 60).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' min' : '0 min'
}
s.durationFormat = function (value) {
	const parsedValue = parseFloat(value)
	return Math.floor(parsedValue / 60) + ':' + (parsedValue % 60 < 10 ? '0' : '') + (parsedValue % 60)
}
s.relativeTime = function (value, zone) {
	if (zone && value) return moment.parseZone(value).fromNow()
	return (value) ? moment.unix(value).fromNow() : '-'
}
s.fromNow = function (value) {
	return (value) ? moment.parseZone(value).fromNow() : '-'
}
s.currency = function (value, currencyCode, digits) {
	if (!value) value = 0
	if (!currencyCode) return '?' + value.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits }).replace(/[A-Z]+/, '') + ' ###'
	if (!digits) digits = 2
	currencyCode = currencyCode.toUpperCase()

	return value.toLocaleString(undefined, { style: 'currency', currency: currencyCode, minimumFractionDigits: digits, maximumFractionDigits: digits })
		.replace(/[A-Z]+/, '') + ' ' + currencyCode
}
s.numberFormat = function (value, currencyType) {
	if (!value) return '-'
	return new Intl.NumberFormat('en-GB', { style: 'currency', currency: currencyType, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
}
s.seconds = function (value) {
	if (!value) return '-'
	return Math.round(value) + 's'
}
s.decToPercent = function (value) {
	if (!value) return '-'
	return Math.round(value * 100) + '%'
}
s.groupByHour = function (value) {
	let txt = (moment(value).isSame(moment(), 'day') ? 'Today ' : 'Yesterday ')
	const hour1 = moment(value).add(1, 'hours')
	txt += moment(value).format('H[:00] - ')
	if (moment().diff(moment(hour1), 'seconds') > 0) {
		txt += hour1.format('H[:00]')
	} else {
		txt += 'now'
	}
	return txt
}
export default s
