<template>
	<v-card>
		<v-card-title>
			<span class="text-h5">{{formData.name}}</span>
		</v-card-title>
		<v-card-text>
			<v-form ref="activeForm" :key="formData.index">
				<v-row>
					<v-col v-for="(field, schemaIndex) in formData.schema" :key="schemaIndex" :cols="field && field.width ? field.width / 2 : 12">
						<dynamic-field :schema="formData.schema" :name="field.name" v-model="dynamicFormObj[field.name]"></dynamic-field>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
			color="blue darken-1" text @click="closeFormDialog">
				Close
			</v-btn>
			<v-btn color="blue darken-1" text @click="submit">
				Submit
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import DynamicField from '@/components/Dynamic-Field.vue'
export default {
	components: { DynamicField },
	props: {
		fillData: {
			type: Object,
			default: () => { return {} },
			description: 'The data to use to populate/fill the form before rendering.'
		},
		formData: {
			type: Object,
			default: () => { return {} },
			description: 'Representing a form\'s config data. Not to be confused with the values of then form fields.'
		}
	},
	data () {
		return {
			dynamicFormObj: this.fillData
		}
	},
	watch: {
		dynamicFormObj (newVal) {
			this.$emit('dynamicFormObj change', this.dynamicFormObj)
		}
	},
	methods: {
		closeFormDialog () {
			this.$emit('close')
		},
		submit () {
			const formObj = { activeForm: this.$refs.activeForm, formData: this.dynamicFormObj, scriptForgeId: this.formData.scriptforge_id }
			this.$emit('submit', formObj)
		}
	}
}
</script>
