import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
// import DevProvDetails from './config/dev-prov-details.js'
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		prov: {},
		uuid: '',
		button: {},
		voicemail: {},
		cdr: [],
		pushRegistration: {},
		// isOnline: false,
		// active: false,
		contact: JSON.parse(localStorage.getItem('contact')) || [],
		log: JSON.parse(localStorage.getItem('log')) || []
	},
	mutations: {
		addCdr (state, payload) {
			state.log.push(payload)
			localStorage.setItem('log', JSON.stringify(state.log))
		},
		deleteCdr (state, payload) {
			Vue.set(state, 'log', state.log.filter(log => !payload.includes(log.id)))
			localStorage.setItem('log', JSON.stringify(state.log))
		},
		addContact (state, payload) {
			state.contact.push({ ...payload, id: uuidv4() })
			localStorage.setItem('contact', JSON.stringify(state.contact))
		},
		updateContact (state, payload) {
			Vue.set(state, 'contact', state.contact.map(x => {
				if (x.id !== payload.id) return x
				return payload
			}))
			localStorage.setItem('contact', JSON.stringify(state.contact))
		},
		deleteContact (state, payload) {
			Vue.set(state, 'contact', state.contact.filter(contact => !payload.includes(contact.id)))
			localStorage.setItem('contact', JSON.stringify(state.contact))
		},
		setProv (state, payload) {
			Vue.set(state, 'prov', payload)
		},
		getUuid (state) {
			state.uuid = localStorage.getItem('uuid') || uuidv4()
			localStorage.setItem('uuid', state.uuid)
			document.cookie = 'uuid=' + state.uuid
		},
		setButton (state, payload) {
			Vue.set(state, 'button', payload)
		},
		setVoicemail (state, payload) {
			Vue.set(state, 'voicemail', payload)
		},
		setCdr (state, payload) {
			Vue.set(state, 'cdr', payload)
		}
	},
	actions: {
		getCdr (context) {
			if (context.state.prov.standalone) return
			return axios.get(`/api/softphone/cdr?uuid=${context.state.uuid}`, {
				timeout: 150000
			})
			.then(res => {
				context.commit('setCdr', res.data)
				return res.data
			})
			.catch(this.throwErr)
		},
		getVoicemail (context) {
			if (context.state.prov.standalone) return
			return axios.get(`/api/softphone/voicemail?uuid=${context.state.uuid}`, {
				timeout: 150000
			})
			.then(res => {
				context.commit('setVoicemail', res.data)
				return res.data
			})
			.catch(this.throwErr)
		},
		getProv (context) {
			if (context.state.prov.standalone) return
			return axios.get(`/api/softphone/prov?uuid=${context.state.uuid}`, {
				timeout: 150000
			})
			.then(res => {
				if (res.data.sipUser) {
					localStorage.setItem('SIPConfig', JSON.stringify(res.data.sipUser))
				}
				context.commit('setProv', res.data)
				return res.data
			})
			.catch(this.throwErr)
		},
		getButton (context) {
			if (context.state.prov.standalone) return
			return axios.get(`/api/softphone/button?uuid=${context.state.uuid}`, {
				timeout: 150000
			})
			.then(res => {
				context.commit('setButton', res.data)
				return res.data
			})
			.catch(this.throwErr)
		}
		// getJob (context) {
		// 	if (context.state.prov.standalone) return
		// 	return axios.get(`/api/softphone/button?uuid=${context.state.uuid}`, {
		// 		timeout: 150000
		// 	})
		// 	.then(res => {
		// 		context.commit('setButton', res.data)
		// 		return res.data
		// 	})
		// 	.catch(this.throwErr)
		// }
	}
})
